// ####### Declarations ##########
import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

// ########### Styled Components ############
const DialogBox = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: block;
  overflow: auto;
  background: rgba(0,0,0,0.6);
  padding: 0.5rem 6rem;
`;
const DialogContainer = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  background: rgba(255,255,255);
  padding: 1rem;
  border-radius: 6px;
  box-shadow: 0 5px 14px 2px rgba(0, 0, 0, 0.4);

  // > img {
  //   display: block;
  //   max-width: 100%;
  //   max-height: 80vh;
  //   left: 0;
  //   right: 0;
  //   margin: auto;
  // }
`;
const DialogBackdrop = styled.div`
`;

// ########### Helper Components ############
const DialogPortal = props => ReactDOM.createPortal(
  props.children,
  document.getElementById('modal-portal')
);

// ########### Composing View ############
const Dialog = (props) => {
  if (props.open) {
    document.body.style.overflow = 'hidden';
  }

  if (!props.open) {
    document.body.style.overflow = 'auto';
    return null;
  }

  const onClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <DialogPortal>
      <DialogBox onClick={props.onDialogClick} className="animated fadeIn faster">
        <DialogContainer onClick={onClick} className="animated fadeIn fast">
          {props.children}
        </DialogContainer>
        <DialogBackdrop />
      </DialogBox>
    </DialogPortal>
  );
};

// ########### Export ############
export default Dialog;
