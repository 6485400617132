import React, { Component } from 'react';

import Form from '../../../shared/ui/form/form';

// Main component
class MenuElement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      opened: false
    };

    this.toggleView = this.toggleView.bind(this);
  }

  toggleView() {
    this.setState(prevState => ({
      opened: !prevState.opened
    }));
  }

  render() {
    const { name } = this.props;
    const { opened } = this.state;

    if (!opened) {
      return (
        <div
          className="setting-list"
          role="button"
          tabIndex={0}
          name={name}
          onClick={this.toggleView}
        >
          <span className="setting-inline-element one"><b> {this.props.leftInsideText} </b> </span>
          <span className="setting-inline-element two">{this.props.rightInsideText}</span>
          <span className="setting-inline-element three">Edit</span>
        </div>
      );
    }

    return (
      <Form
        api={this.props.API}
        onSubmit={event => this.handleOnSubmit(event)}
        onSuccess={(res, state) => {
          this.toggleView();

          if (this.props.onSuccess) {
            this.props.onSuccess(res, state);
          }

          this.props.refreshData(state);
        }}
        allowEmpty={this.props.allowEmpty || []}
        onError={(err) => { console.log('err', err); }}
        onCancel={this.toggleView}
        onBeforeSubmit={this.props.onBeforeSubmit}
        defaultState={this.props.formState}
        className="openMenuElement"
      >
        {this.props.content}
      </Form>

    );
  }
}

export default MenuElement;
