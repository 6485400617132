import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { size, forEach, map } from 'lodash';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import api from '../album/api';

import Column from './column';
import FreeboxColumn from './freebox';

const Container = styled.div`
  padding: 10px;
  background: #eee;
  width: 100%;
`;
const ContentContainer = styled.div`
  width: 500px;
  left: 0;
  right: 0;
  margin: auto;
`;
const ContentContainerBox = styled.div`
  overflow: auto;
  width: 100%;
  height: auto;
  display: flex;
`;
const ViewBox = styled.div`
  display: flex;
  width: 100%;

  background: #eee;
  border-radius: 11px;
  padding: 4px;
  height: calc(100vh - 150px);
  overflow: auto;
`;
const Btt = styled.button`
  border-radius: 7px;
  padding-top: 1px;
  border: solid 3px #e4e4e4;
  display: block;
  background: #e4e4e4;
  margin-bottom: 10px;
  width: calc(100% - 20px);
  margin: 10px;
  margin-top: -10px;
  height: 119px;
  font-size: 28px;
  color: #a0a0a0;

  > span {
    font-size: 0.9rem;
    display: block;
  }
`;

const HeaderElement = styled.h2`
  border-radius: 8px;
  font-size: 1.5rem;
  padding: 0.5rem;
  color: black;
  margin: 7px;
  background: #f3f3f3;
`;

const ParagraphElement = styled.p`
  border-radius: 8px;
  font-size: 15px;
  padding: 0.5rem;
  color: black;
  margin: 7px;
  background: #f3f3f3;
`;

const asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
};

class InnerColumn extends React.PureComponent {
  render() {
    const { column, taskMap, index, isDrop } = this.props;
    const tasks = column.taskIds.map(taskID => taskMap[taskID]);

    return (
      <Column key={column.id} column={column} tasks={tasks} index={index} isDrop={isDrop} />
    );
  }
}

class InnerFreeboxColumn extends React.PureComponent {
  render() {
    const { column, taskMap, index } = this.props;
    const tasks = column.taskIds.map(taskID => taskMap[taskID]);

    return (
      <FreeboxColumn key={column.id} column={column} tasks={tasks} index={index} styles={this.props.fixed ? {
        position: 'fixed',
        height: '90vh',
        width: '140px',
        overflow: 'auto',
        top: 0
      } : {}} />
    );
  }
}

class ProfileManager extends React.Component {
  state = {
    tasks: {},
    freebox: [],
    columns: {},
    columnsOrder: [],
    isDrop: false
  };

  onDragEnd = (result) => {
    this.setState({
      isDrop: false
    });

    const { destination, source, draggableId, type } = result;

    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId
      && destination.index === source.index
    ) {
      return;
    }

    if (destination.droppableId === 'freebox') {
      const column = this.state.columns[source.droppableId];
      const newTasksIds = Array.from(column.taskIds);
      newTasksIds.splice(source.index, 1);

      const newFreebox = Array.from(this.state.freebox);
      newFreebox.splice(destination.index, 0, draggableId);

      const newColumn = {
        ...column,
        taskIds: newTasksIds
      };

      const newState = {
        ...this.state,
        columns: {
          ...this.state.columns,
          [newColumn.id]: newColumn
        },
        freebox: newFreebox
      };

      return this.setState(newState);
    }

    if (source.droppableId === 'freebox') {
      const column = this.state.columns[destination.droppableId];
      const newTasksIds = Array.from(column.taskIds);
      newTasksIds.splice(source.index, 0, draggableId);

      const newFreebox = Array.from(this.state.freebox);
      newFreebox.splice(source.index, 1);

      const newColumn = {
        ...column,
        taskIds: newTasksIds
      };

      const newState = {
        ...this.state,
        columns: {
          ...this.state.columns,
          [newColumn.id]: newColumn
        },
        freebox: newFreebox
      };

      return this.setState(newState);
    }

    if (type === 'column') {
      const newColumnOrder = Array.from(this.state.columnsOrder);
      newColumnOrder.splice(source.index, 1);
      newColumnOrder.splice(destination.index, 0, draggableId);

      const newState = {
        ...this.state,
        columnsOrder: newColumnOrder
      };

      return this.setState(newState);
    }

    const start = this.state.columns[source.droppableId];
    const finish = this.state.columns[destination.droppableId];

    if (start === finish) {
      const newTasksIds = Array.from(start.taskIds);
      newTasksIds.splice(source.index, 1);
      newTasksIds.splice(destination.index, 0, draggableId);

      const newColumn = {
        ...start,
        taskIds: newTasksIds
      };

      const newState = {
        ...this.state,
        columns: {
          ...this.state.columns,
          [newColumn.id]: newColumn
        }
      };

      return this.setState(newState);
    }

    // moving between columns

    const startTaskIds = Array.from(start.taskIds);
    startTaskIds.splice(source.index, 1);

    const newStart = {
      ...start,
      taskIds: startTaskIds
    };

    const finishTaskIds = Array.from(finish.taskIds);
    finishTaskIds.splice(destination.index, 0, draggableId);

    const newFinish = {
      ...finish,
      taskIds: finishTaskIds
    };

    const newState = {
      ...this.state,
      columns: {
        ...this.state.columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish
      }
    };

    return this.setState(newState);
  }

  addColumn = () => {
    const newColumnId = (size(this.state.columns)).toString();

    const newState = {
      ...this.state,
      columns: {
        ...this.state.columns,
        [newColumnId]: {
          id: newColumnId,
          direction: 'horizontal',
          taskIds: []
        }
      },
      columnsOrder: [...this.state.columnsOrder, newColumnId]
    };

    return this.setState(newState);
  }

  componentWillUnmount = async () => {
    document.body.style.overflow = 'auto';
    document.querySelector('footer').style.display = 'initial';
  }

  componentDidMount = async () => {
    document.body.style.overflow = 'hidden';
    document.querySelector('footer').style.display = 'none';
    window.scrollTo(0, 0);

    const { message: data } = await api.getAllPortfolioImages({ id: 423 });

    await asyncForEach(data.unassigned, async (photo, i) => {
      const res = await api.getPhoto({ id: photo.id });

      data.unassigned[i] = {
        ...photo,
        ...res.message
      };
    });

    await asyncForEach(data.assigned, async (photos, col) => {
      if (photos.length) {
        await asyncForEach(photos, async (photo, row) => {
          const res = await api.getPhoto({ id: photo.id });

          data.assigned[col][row] = {
            ...photo,
            ...res.message
          };
        });
      }
    });

    const tasks = {};
    const tasksIDs = [];
    const columns = {};
    const columnsOrder = map(data.assigned, (_, i) => i);

    data.unassigned.forEach((photo) => {
      tasks[photo.id] = photo;
      tasksIDs.push(photo.id);
    });

    data.assigned.forEach((photos, i) => {
      const index = i.toString();
      columns[index] = {
        id: index,
        direction: 'horizontal',
        taskIds: map(photos, (photo) => {
          tasks[photo.id] = photo;

          return photo.id;
        })
      };
    });

    this.setState(prevState => ({
      ...prevState,
      tasks,
      columns,
      columnsOrder,
      freebox: tasksIDs
    }));
  }

  render() {
    return (
      <DragDropContext
        onDragEnd={this.onDragEnd}
      >
        <ViewBox>
          <InnerFreeboxColumn
            column={{
              id: 'freebox',
              direction: 'vertical',
              taskIds: this.state.freebox
            }}
            index={1}
            taskMap={this.state.tasks}
          />
          <ContentContainerBox>
            <ContentContainer>
              <Droppable droppableId="i" type="column">
                {
                  provided => (
                    <Container
                      {...provided.droppableProps}
                      innerRef={provided.innerRef}
                    >
                      {
                        this.state.columnsOrder.map((columnId, index) => {
                          const column = this.state.columns[columnId];

                          return (
                            <InnerColumn
                              key={column.id}
                              column={column}
                              index={index}
                              taskMap={this.state.tasks}
                              isDrop={this.state.isDrop}
                            />
                          );
                        })
                      }
                      {provided.placeholder}
                    </Container>
                  )
                }
              </Droppable>
              <Btt type="button" onClick={this.addColumn}>
                <i className="material-icons">add_circle</i>
                <span>Add new row</span>
              </Btt>
            </ContentContainer>
            <button type="button" onClick={async () => {
              const saveObject = [];

              forEach(this.state.columns, (column) => {
                forEach(column.taskIds, (id, i) => {
                  saveObject.push([parseInt(column.id, 10), i, id]);
                });
              });

              await asyncForEach(saveObject, async ([row, column, id]) => {
                await api.addToPortfolio({ row, column, id });
              });

              await asyncForEach(this.state.freebox, async (id) => {
                await api.addToPortfolio({ id });
              });
            }}>Save
            </button>
          </ContentContainerBox>
        </ViewBox>
      </DragDropContext>
    );
  }
}

export default ProfileManager;
