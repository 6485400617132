import * as constants from '../constants';

export const setUsersAlbums = albums => ({
  type: constants.SET_USER_ALBUMS,
  payload: albums
});

export const setAlbumDetails = content => ({
  type: constants.SET_ALBUM_DETAILS,
  payload: content
});
