// ####### Declarations ##########

import React from 'react';
import ShareApi from 'core/api/share';
import Routes from '../routes';
import styled from 'styled-components';

import { Route, Link } from 'react-router-dom';

import CreateComponent from 'self/component';
import AppLayout from 'components/layouts/default/default';

import ShareAlbum from 'modules/share/album/album';
import SharePhoto from 'modules/share/photo/photo';
import ShareProfile from 'modules/share/profile/profile';

// ####### Declaring Styles ##########
const ViewCover = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #fff;
`;

// ####### Declaring View ##########
class NotFound extends CreateComponent {
  constructor(props) {
    super(props);

    this.types = {
      album: 'album',
      photo: 'photo',
      user: 'user'
    };

    this.state = {
      ready: false,
      type: null,
      data: {}
    };
  }

  async componentDidMount() {
    const { location } = this.props;
    const { pathname } = location;

    const checked = await ShareApi.checkURI({
      domain: window.location.hostname,
      uri: pathname.replace('/', '')
    });

    if (checked.success && checked.message) {
      return this.dispatch({
        type: this.CONSTS.ACTION.READY,
        payload: {
          type: this.types[checked.message.type.toLowerCase()],
          data: checked.message
        }
      });
    }

    return this.dispatch({ type: this.CONSTS.ACTION.READY });
  }

  render() {
    const { ready, type } = this.state;
    const { album, photo, user } = this.types;
    const { location } = this.props;
    const { pathname } = location;

    if (!ready) {
      return (
        <ViewCover>
          <div className="imglish-logo-loader">
            <div className="imglish-logo-loader-head" />
            <div className="imglish-logo-loader-body" />
          </div>
        </ViewCover>
      );
    }

    if (type === album) {
      return (
        <Route path={pathname} render={props => <ShareAlbum {...props} data={this.state.data} />} />
      );
    }

    if (type === photo) {
      return (
        <Route path={pathname} component={SharePhoto} />
      );
    }

    if (type === user) {
      return (
        <Route path={pathname} component={ShareProfile} />
      );
    }

    return (
      <AppLayout>
        <div className="animated fadeIn">
          <h1>404 Not Found</h1>
          <p>Oops... The page you&apos;ve tried to reach is not available.</p>
          <Link to={Routes.DashboardRoutePath} className="back">
            Back to Dashboard
          </Link>
        </div>
      </AppLayout>
    );
  }
}

// ####### Export ##########
export default NotFound;
