// Imports
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { mapValues } from 'lodash';

import './settings.css';

import Authenticate from 'components/authenticate/authenticate';
import AppLayout from 'components/layouts/default/default';
import styled from 'styled-components';

import UserApi from '../../core/api/APIsettings';
import MenuElement from './partial/menuElement';
import Input from '../../shared/ui/input/input';
import { FormGroup } from '../../shared/styled/styled';
import App from '../../core/app';
import { Status } from '../../shared/ui/ui';
import { CreateReduxComponent } from '../../self/component';
import { dConnect } from '../../shared/utils/helpers';
import { setUserData } from '../../actions/user';

const FormContainer = styled.div`
  max-width: 540px;
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;

  ${FormGroup} {
    width: 100%;
  }
`;

const ButtonSeparator = styled.div`
  margin-right: 1.33rem;
`;

const UserAvatarBox = styled.div`
  background: #fff;
  width: 204px;
  height: 204px;
  border-radius: 5px;
  border: 2px solid #2DA75E;
  overflow: hidden;
`;

const UserAvatar = styled.div`
  background-size: cover;
  height: 100%;
`;

const UserAvatarPlaceholder = styled.div`
  background: #e8e8e8;
  width: 200px;
  height: 200px;
  border-radius: 5px;
  display: grid;
  align-items: center;
  justify-items: center;
  overflow: hidden;

  > i 
  {
    color: #adadad;
    font-size: 78px;
  }
`;

// Main component
class Settings extends CreateReduxComponent {
  constructor(props) {
    super(props);

    const user = props.app.user.details;

    const { first_name, last_name, email } = user;
    const { domain, uri } = user.usermeta.url;
    let avatarPath = '';

    if (Object.prototype.hasOwnProperty.call(user, 'file')) {
      avatarPath = user.file.path;
    }

    this.state = {
      first_name,
      last_name,
      email,
      uri,
      domain,
      avatarPath
    };

    this.refreshData = this.refreshData.bind(this);
  }

  refreshData() {
    UserApi.setUserData().then((res) => {
      const { first_name, last_name, email } = res.message;
      const { domain, uri } = res.message.usermeta.url;
      let path = '';

      if (res.message.file !== undefined) {
        path = res.message.file.path;
      }

      this.setState({
        first_name,
        last_name,
        email,
        uri,
        domain,
        avatarPath: path
      });

      this.dispatch(setUserData(res.message));
    });
  }

  getUserNameEditor() {
    const { first_name, last_name } = this.state;

    const formState = {
      first_name,
      last_name
    };

    return (
      <MenuElement
        API={UserApi.edit}
        refreshData={this.refreshData}
        leftInsideText="Your name"
        rightInsideText={first_name + ' ' + last_name}
        formState={formState}
        content={
          actions => (
            <FormContainer>
              <h4 style={{ paddingTop: 0 }}>Manage your personal details</h4>
              <p>People do not change names very often. Anyway, here you can update your name and surname <span role="img" title="What's wrong is not us" aria-label="What's wrong is not us">😇</span></p>
              <br />
              <Input
                name="first_name"
                label="First name:"
                type="text"
                value={actions.state.first_name.value}
                onChange={event => actions.handleChange(event)}
              />
              <Input
                name="last_name"
                label="Last name:"
                type="text"
                value={actions.state.last_name.value}
                onChange={event => actions.handleChange(event)}
              />
              <ButtonContainer>
                <Input
                  type="submit"
                  value="Save changes"
                  className="button"
                />
                <ButtonSeparator />
                <Input
                  type="Button"
                  value="Cancel"
                  className="button cancel-button"
                  onClick={actions.props.onCancel}
                />
              </ButtonContainer>
            </FormContainer>
          )
        }
      />
    );
  }

  getUserPasswordEditor() {
    const formState = {
      password: ''
    };

    return (
      <MenuElement
        API={UserApi.edit}
        refreshData={this.refreshData}
        leftInsideText="Change password"
        rightInsideText="Remember to use strong and unique password"
        formState={formState}
        onBeforeSubmit={(state) => {
          if (state.password.value === state.confirmPassword.value) {
            return true;
          }

          // alert('Passwords are not matching!');
          return false;
        }}
        content={
          actions => (
            <FormContainer>
              <h4 style={{ paddingTop: 0 }}>Manage your password</h4>
              <p>Strong password means safe account. We reccoment you to use long password <span role="img" title="Always use strong password" aria-label="Always use strong password">🔑</span></p>
              <br />
              <Input
                showSwitch
                name="password"
                type="password"
                label="New password:"
                onChange={event => actions.handleChange(event)}
              />
              <Input
                showSwitch
                name="confirmPassword"
                type="password"
                label="Confirm password:"
                onChange={event => actions.handleChange(event)}
              />
              <ButtonContainer>
                <Input
                  type="submit"
                  value="Save changes"
                  className="button"
                />
                <ButtonSeparator />
                <Input
                  type="Button"
                  value="Cancel"
                  className="button cancel-button"
                  onClick={actions.props.onCancel}
                />
              </ButtonContainer>
            </FormContainer>
          )
        }
      />
    );
  }

  getUserAvatarEditor() {
    const { avatarPath } = this.state;

    let file;

    const modifier = () => file;

    const checkUserAvatar = () => {
      if (avatarPath !== '') {
        return (
          <UserAvatarBox>
            <UserAvatar style={{ backgroundImage: `url(${avatarPath})` }} alt="Something went wrong with displaying your avatar" />
          </UserAvatarBox>
        );
      }

      return (
        <UserAvatarPlaceholder>
          <i className="material-icons">person</i>
        </UserAvatarPlaceholder>
      );
    };

    return (
      <MenuElement
        API={UserApi.uploadAvatar}
        refreshData={this.refreshData}
        leftInsideText="Avatar"
        rightInsideText="Here you can change your avatar"
        onBeforeSubmit={() => {
          if (file.type === 'image/jpeg' || file.type === 'image/png') {
            App.get('globalStatus').setContextState({
              status: {
                mode: 'toast',
                type: 'info',
                message: 'Uploading your avatar...',
                time: 120000,
                noScroll: true
              }
            });

            return true;
          }

          return false;
        }
        }
        content={
          actions => (
            <FormContainer>
              <h4 style={{ paddingTop: 0 }}>Change your avatar</h4>
              <p>Do it here</p>
              {checkUserAvatar()}
              <br />
              <Input
                name="avatar"
                type="file"
                label="Pick a file:"
                onChange={(event) => {
                  file = event.target.files[0];
                  actions.handleChange(event, modifier);
                }}
              />
              <ButtonContainer>
                <Input
                  type="submit"
                  value="Save changes"
                  className="button"
                />
                <ButtonSeparator />
                <Input
                  type="Button"
                  value="Cancel"
                  className="button cancel-button"
                  onClick={actions.props.onCancel}
                />
              </ButtonContainer>
            </FormContainer>
          )
        }
      />
    );
  }

  getUserEmailEditor() {
    const { email } = this.state;

    const formState = {
      email
    };

    return (
      <MenuElement
        API={UserApi.edit}
        refreshData={this.refreshData}
        leftInsideText="Contact"
        rightInsideText={email}
        formState={formState}
        content={
          actions => (
            <FormContainer>
              <h4 style={{ paddingTop: 0 }}>Manage your contact details</h4>
              <p>Your email is on the same time your login to imglish. We will use it to contact with you in any problem with your account.</p> {/* eslint-disable-line max-len */}
              <br />
              <Input
                name="email"
                type="email"
                label="Email:"
                value={actions.state.email.value}
                onChange={event => actions.handleChange(event)}
              />
              <ButtonContainer>
                <Input
                  type="submit"
                  value="Save changes"
                  className="button"
                />
                <ButtonSeparator />
                <Input
                  type="Button"
                  value="Cancel"
                  className="button cancel-button"
                  onClick={actions.props.onCancel}
                />
              </ButtonContainer>
            </FormContainer>
          )
        }
      />
    );
  }

  getUserNickEditor() {
    const { uri, domain } = this.state;

    const formState = { uri, domain };

    return (
      <MenuElement
        API={UserApi.edit}
        refreshData={this.refreshData}
        leftInsideText="Profile address"
        rightInsideText={window.location.protocol + '//' + domain + '/' + uri}
        allowEmpty={['domain']}
        formState={formState}
        onSuccess={(_, state) => {
          if (!state.domain.value.length) {
            state.domain.value = this.state.domain; // eslint-disable-line no-param-reassign
          }
        }}
        onBeforeSubmit={(state) => {
          if (state.domain.value === this.state.domain) {
            state.domain.value = ''; // eslint-disable-line no-param-reassign
          }

          return true;
        }}
        content={
          actions => (
            <FormContainer>
              <h4 style={{ paddingTop: 0 }}>Manage your profile address</h4>
              <p>
                You can manage here your profile settings.<br />
                <span>Your {uri === actions.state.uri.value ? 'current profile link is' : 'profile link will be'}: </span>
                <a style={{ wordBreak: 'break-all' }} data-type="link" href={`//${domain}/${uri}`} rel="noopener noreferrer" target="_blank">{window.location.protocol}{'//'}{domain}/<b>{actions.state.uri.value}</b></a>
              </p>
              <br />
              <Input
                name="uri"
                type="text"
                label="imglish nickname:"
                afterLabel={(
                  <Status
                    key="uri"
                    message={(
                      <div>
                        <h6 style={{ marginTop: 0 }}>How do imglish use my nickname?</h6>
                        <span>Your nickname is used to display your imglish profile.</span>
                      </div>
                    )}
                    type="info"
                  />
                )}
                value={actions.state.uri.value}
                onChange={event => actions.handleChange(event, v => v.toLowerCase())}
                maxLength={254}
              />
              <ButtonContainer>
                <Input
                  type="submit"
                  value="Save changes"
                  className="button"
                />
                <ButtonSeparator />
                <Input
                  type="Button"
                  value="Cancel"
                  className="button cancel-button"
                  onClick={actions.props.onCancel}
                />
              </ButtonContainer>
            </FormContainer>
          )
        }
      />
    );
  }

  getUserDomainEditor() {
    const { uri, domain, first_name, last_name } = this.state;

    const formState = { uri, domain };
    const domainExample = `${first_name.toLowerCase()}${last_name.toLowerCase()}.com`;

    return (
      <MenuElement
        API={UserApi.edit}
        refreshData={this.refreshData}
        leftInsideText="Your own domain"
        rightInsideText={(domain !== 'dev.imglish.com' && domain !== 'imglish.com') ? domain : "You don't have your domain connected"}
        allowEmpty={['domain']}
        formState={formState}
        onSuccess={(_, state) => {
          if (!state.domain.value.length) {
            state.domain.value = this.state.domain; // eslint-disable-line no-param-reassign
          }
        }}
        onBeforeSubmit={(state) => {
          if (state.domain.value === this.state.domain) {
            state.domain.value = ''; // eslint-disable-line no-param-reassign
          }

          return true;
        }}
        content={
          actions => (
            <FormContainer>
              <h4 style={{ paddingTop: 0 }}>Connect your own domain</h4>
              <Status
                key="domain"
                message={(
                  <div>
                    <h6 style={{ marginTop: 0 }}>Do you have your own domain?</h6>
                    <span>Great! You can make imglish even more yours here <span role="img" title="hurray!" aria-label="hurray!">👏.</span></span>
                    <br />
                    <span>{`Enter your domain below (eg. ${domainExample}), and transform your profile into your website 😍`}</span>
                  </div>
                )}
                type="info"
              />
              <Input
                name="domain"
                type="text"
                label="Domain address:"
                placeholder={`Enter your domain here (eg. ${domainExample})`}
                onChange={event => actions.handleChange(event)}
            />
              <ButtonContainer>
                <Input
                  type="submit"
                  value="Save changes"
                  className="button"
                />
                <ButtonSeparator />
                <Input
                  type="Button"
                  value="Cancel"
                  className="button cancel-button"
                  onClick={actions.props.onCancel}
                />
              </ButtonContainer>
            </FormContainer>
          )
        }
      />
    );
  }

  render() {
    return (
      <AppLayout>
        <Authenticate>
          <div className="animated fadeIn">
            <h3 style={{ margin: '0 0 0.33rem' }}>Profile Settings</h3>
            <h5 className="light mt0" style={{ color: 'rgb(123, 123, 123)', margin: '0 0 3rem' }}>{this.state.first_name}, you can change your profile settings here</h5>
            <div className="setting-box">
              {this.getUserNameEditor()}
              {this.getUserPasswordEditor()}
              {this.getUserAvatarEditor()}
              {this.getUserEmailEditor()}
              {this.getUserNickEditor()}
              {this.getUserDomainEditor()}
            </div>
          </div>
        </Authenticate>
      </AppLayout>
    );
  }
}

export default dConnect(Settings);
