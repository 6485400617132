import React from 'react';

import { Link } from 'react-router-dom';
import { BrandSygnet, BrandSygnetBase, BrandSygnetDot } from 'components/components.styled';

import Form from 'shared/ui/form/form';
import Input from 'shared/ui/input/input';
import Api from '../../../core/api/APIforgotPass';
import App from '../../../core/app';

const ForgotPassword = props => (
  <Form
    api={Api.restore}
    onSuccess={(res) => {
      App.get('globalStatus').setContextState({
        status: {
          mode: 'toast',
          type: 'success',
          message: 'New password should be on your email, check it 😉',
          time: 5000,
          noScroll: true
        }
      });
    }}
    onError={() => {
      App.get('globalStatus').setContextState({
        status: {
          mode: 'toast',
          type: 'warning',
          message: "We couldn't find this email in our database... 😔",
          time: 5000,
          noScroll: true
        }
      });
    }}
    defaultState={{
      email: ''
    }}
    >
    {
      actions => (
        <div className="formDiv">
          <BrandSygnet styled={`
            transform: scale(1.5);
            left: 0;
            right: 0;
            margin: auto;
            margin-bottom: -2rem;
            margin-top: 4rem;
          `}>
            <BrandSygnetDot styled={`
              background: #f3f3f3;
            `} />
            <BrandSygnetBase styled={`
              background: #f3f3f3;
            `} />
          </BrandSygnet>
          <h3>Forgotten your password?</h3>
          <p style={{ fontSize: '1.16rem', marginTop: '-0.33rem', color: '#565656' }}>No worries. Please just provide your email. We'll send you an email with reset password link. It's usually tooks less then 59sec to be back <span role="img" aria-label="happy">😇</span></p>
          <br />
          <Input name="email" type="email" placeholder="Email" value={actions.state.email.value} valid={actions.state.email.valid} onChange={event => actions.handleChange(event)} />
          <Input type="submit" value="Sign In" className="button" style={{ width: '100%' }} />
          <Link className="link" to="/signup">
            Don't have an account? Sign up →
          </Link>
          <Link className="link" to="/signin">
            Log in here →
          </Link>
          <br />
        </div>
      )
    }
  </Form>
);

export default ForgotPassword;
