import styled from 'styled-components';

export const BrandSygnet = styled.div`
  transform: scale(0.7);
  width: 40px;
  height: 40px;
  display: flex;
  background: #2DA75E;
  border-radius: 7px;
  margin-right: 2px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  ${props => props.styled}
`;

export const BrandSygnetDot = styled.div`
  width: 10px;
  height: 10px;
  background: #fff;
  border-radius: 50%;

  ${props => props.styled}
`;

export const BrandSygnetBase = styled.div`
  width: 14px;
  height: 18px;
  border-top-left-radius: 10px;
  background: #fff;  
  border-top-right-radius: 10px;
  margin-top: 2px;
  position: relative;
  top: 1px;

  ${props => props.styled}
`;

export const BrandName = styled.div`
  color: #2DA75E;
  font-weight: 900;
  font-size: 1.3rem;
  display: inline-block;

  @media (max-width: 480px) {
    display: none;
  }

  ${props => props.styled}
`;

export const ActionsBar = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1.33rem;

  > * {
    margin: 0 0 0 1rem;
  }

  a {
    display: inline-block;
  }
`;

export const UserInfoBox = styled.div`
  display: flex;
  align-items: center;
`;

export const UserAvatar = styled.div`
  width: 2.33rem;
  height: 2.33rem;
  border-radius: 5px;
  margin-right: 0.66rem;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;

  > i {
    color: #adadad
  }
`;
