import Config from 'config/config';
import { setHeaders } from 'shared/utils/url';
import axios from 'axios';

import App from '../app';
import AuthApi from './auth';
import Routes from '../../modules/routes';

const get = () => fetch(Config.serverURL + '/user/get', {
  method: 'GET',
  headers: setHeaders()
});

const setUserData = () => get().then((res) => {
  if (res.success) {
    App.set('user', res.message);
  } else {
    AuthApi.dropSession();
    this.props.history.push(Routes.SignInRoutePath);
  }

  return res;
});

const edit = pms => fetch(Config.serverURL + '/user/edit', {
  method: 'POST',
  headers: setHeaders(),
  body: JSON.stringify(pms)
});

const uploadAvatar = async (data) => {
  const formData = new FormData();
  formData.append('avatar', data.avatar);

  const request = await axios({
    url: Config.serverURL + '/user/edit',
    method: 'POST',
    headers: setHeaders({ axios: true, auth: true, multidata: true }),
    data: formData
  });

  return request.data;
};

export default {
  edit,
  get,
  setUserData,
  uploadAvatar
};
