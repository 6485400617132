// ####### Declarations ##########

import React, { Component } from 'react';
import Dropdown, { MenuItem, DropdownMenu } from '@trendmicro/react-dropdown';

import Api from '../api';
import App from '../../../core/app';
import PropTypes from 'prop-types';

import { rgbToHex } from 'shared/utils/helpers';
import { Draggable } from 'react-beautiful-dnd';

import * as MS from '../../modules.styled';
import getAverageColor from 'get-average-color';
import CircularProgressBar from '../../../shared/ui/progress/circular';

// ########### Composing view ###########

class Photo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uploaded: false,
      uploadedPhoto: null,
      inProgress: false,
      progress: 0
    };

    this.cancelUpload = () => {};

    this.getPhoto = this.getPhoto.bind(this);
    this.deletePhoto = this.deletePhoto.bind(this);
    this.uploadPhoto = this.uploadPhoto.bind(this);
    this.setAsAlbumCover = this.setAsAlbumCover.bind(this);
    this.getPhotoPlaceholder = this.getPhotoPlaceholder.bind(this);
  }

  deletePhoto(id) {
    Api.deletePhoto({ id }).then((res) => {
      if (res.success) {
        this.props.updateAlbum(id, () => {
          App.get('globalStatus').setContextState({
            status: {
              mode: 'toast',
              type: 'success',
              message: 'Photo deleted',
              noScroll: true
            }
          });
        });
      }
    });
  }

  setAsAlbumCover(id) {
    const { id: albumID, description, name } = this.props.album;

    Api.updateAlbum({
      name,
      description,
      id: albumID,
      photo_id: id
    }).then((res) => {
      if (res.success) {
        App.get('globalStatus').setContextState({
          status: {
            mode: 'toast',
            type: 'success',
            message: 'Album cover photo updated :)',
            noScroll: true
          }
        });
      }
    });
  }

  getPhoto() {
    let image = '';
    let color = '';
    let photo = null;
    let sizeKey = 'min';

    const { file, id, grid, containerWidth, onClick, pid, index } = this.props;
    const { uploaded, uploadedPhoto } = this.state;
    const photoWidth = containerWidth / grid;

    if (photoWidth < file.thumbnail.min.meta.width) {
      sizeKey = 'min';
    } else if (photoWidth < file.thumbnail.mid.meta.width) {
      sizeKey = 'mid';
    } else {
      sizeKey = 'max';
    }

    if (uploaded) {
      image = uploadedPhoto.thumbnail[sizeKey].path;
      color = uploadedPhoto.color; // eslint-disable-line prefer-destructuring
      photo = uploadedPhoto;
    } else {
      image = file.thumbnail[sizeKey].path;
      color = file.color; // eslint-disable-line prefer-destructuring
      photo = file;
    }

    return (
      <Draggable draggableId={pid} index={index}>
        {
          provided => (
            <MS.PhotoWrapper
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              innerRef={provided.innerRef}
              grid={grid}
            >
              <MS.MenuWrapper>
                <Dropdown>
                  <Dropdown.Toggle
                    btnStyle="flat"
                    noCaret
                    style={{ padding: 0 }}
                  >
                    <MS.PhotoMenuIcon className="material-icons">more_horiz</MS.PhotoMenuIcon>
                  </Dropdown.Toggle>
                  <DropdownMenu>
                    <MenuItem
                      onSelect={async () => {
                        const res = await Api.addToPortfolio({ id });
                        const res2 = await Api.getAllPortfolioImages();

                        console.log(res);
                        console.log(res2);
                      }}
                    >
                      Add to portfolio
                    </MenuItem>
                    <MenuItem
                      onSelect={() => this.setAsAlbumCover(id)}
                    >
                      Set as album cover
                    </MenuItem>
                    <MenuItem divider />
                    <MenuItem
                      onSelect={() => this.deletePhoto(id)}
                    >
                      <span style={{ color: '#c5292f' }}>Remove Photo</span>
                    </MenuItem>
                  </DropdownMenu>
                </Dropdown>
              </MS.MenuWrapper>
              <MS.Photo
                noMargin
                onClick={onClick}
                key={'si-image-' + id}
                grid={grid}
                containerWidth={containerWidth}
                photo={photo}
                tabIndex="0"
                role="button"
                style={{ backgroundImage: 'url(' + image + ')', backgroundColor: '#' + color }}
              />
            </MS.PhotoWrapper>
          )
        }
      </Draggable>
    );
  }


  getPhotoPlaceholder() {
    const { index, grid, containerWidth } = this.props;
    const { inProgress, progress } = this.state;

    return (
      <MS.PhotoLoader key={index} grid={grid} containerWidth={containerWidth}>
        <CircularProgressBar percentage={progress} />
        <button type="button" onClick={() => {
          this.cancelUpload();
          this.props.setCanceled(this.props.file);
        }}>Cancel
        </button>
      </MS.PhotoLoader>
    );
  }

  uploadPhoto() {
    const { file, album, setUploaded, setPreUploaded, setStatus, pid } = this.props;

    const reader = new FileReader();

    reader.onload = () => {
      getAverageColor(reader.result).then((color) => {
        // run other photo

        setPreUploaded();

        const { request, source } = Api.uploadPhoto({
          album_id: album.id,
          name: file.name,
          description: '',
          photo: reader.result,
          color: rgbToHex(color.r, color.g, color.b)
        }, {
          onUploadProgress: (progress) => {
            this.setState({
              inProgress: true,
              progress
            });
          }
        });

        this.cancelUpload = source.cancel;

        request.then((res) => {
          if (res.success) {
            file.uploaded = true;

            setUploaded({
              pid,
              file,
              photo: res.message
            });
          } else {
            setStatus({
              type: 'error',
              message: 'We had problems to upload your photos. Sorry about that. Please refresh page and try again.'
            });
          }
        }).catch(() => {
          setStatus({
            type: 'error',
            message: 'We had problems to upload your photos. Sorry about that. Please refresh page and try again.'
          });
        });
      });
    };

    reader.onabort = () => console.log('file reading was aborted'); // eslint-disable-line no-console
    reader.onerror = () => console.log('file reading has failed'); // eslint-disable-line no-console

    reader.readAsDataURL(file);
  }

  componentDidUpdate() {
    const { type, upload } = this.props;
    const { uploaded, inProgress, progress } = this.state;

    if (inProgress === false) {
      if (!uploaded && upload && type === 'new-image') {
        this.uploadPhoto();
      }
    }
  }

  componentDidMount() {
    const { type, upload } = this.props;

    if (upload && type === 'new-image') {
      this.uploadPhoto();
    }
  }

  render() {
    const { type } = this.props;
    const { uploaded } = this.state;

    if (type === 'saved-image' || uploaded) {
      return this.getPhoto();
    }

    return this.getPhotoPlaceholder();
  }
}

Photo.defaultProps = {
  setStatus: () => {},
  setUploaded: () => {},
  onClick: () => {},
  updateAlbum: () => {},
  upload: false
};

Photo.propTypes = {
  type: PropTypes.oneOf(['saved-image', 'new-image']).isRequired,
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  index: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  pid: PropTypes.number.isRequired,
  file: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  album: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  grid: PropTypes.number.isRequired,
  containerWidth: PropTypes.number.isRequired,
  setStatus: PropTypes.func,
  setUploaded: PropTypes.func,
  onClick: PropTypes.func,
  updateAlbum: PropTypes.func,
  upload: PropTypes.bool
};

export default Photo;
