// ######### Imports ####################
import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import AuthApi from '../../core/api/auth';
import { ROUTE_PATH } from './route-path';

import Album from './album';

// ######### Composing Routes ####################
const Routes = () => {
  const ready = AuthApi.checkSession();

  if (!ready) {
    return <Redirect to="/signin" />;
  }

  return (
    <Route exact path={ROUTE_PATH} component={Album} />
  );
};

// ######### Exports ####################
export default Routes;
