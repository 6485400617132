// ####### Declarations ##########

import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';


import AppLayout from 'components/layouts/default/default';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import AuthApi from 'core/api/auth';
import { ModuleContainer } from '../modules.styled';

import Dashboard from '../dashboard/dashboard';
import Routes from '../routes';

import JuliaPhoto1 from '../../assets/5af9aeef21bd9338588794.jpg';
import LogoFooter from '../../assets/lg.png';

// ########### Composing view ###########

const Subtitle = styled.h3`
  color: #565656/*#929292*/;
  line-height: 1.66em;
  font-size: 1.16em;
  font-weight: 500;
  margin-bottom: 3em;
  margin-top: 0.66em;
  max-width: 39rem;
  display: inline-block;
`;

const ButtonBox = styled.div`
  font-size: 18px;
`;

const PhotoBar = styled.figure`
  ${props => props.src && `
    width: ${props.width || '100%'};
    height: 32rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-image: url(${props.src});
    margin: 0;
    margin-top: 4rem;
  `}

  border-radius: 1rem;
  color: #fff;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 1.33rem;
  box-sizing: border-box;
  font-weight: 500;
`;

const UserBadge = styled.div`
  color: #333;
  background: #fff;
  padding: 0.66rem 1rem;
  border-radius: 0.66rem;
  font-size: 0.875rem;
  font-weight: 600;
  display: flex;
  align-items: center;

  > i.material-icons {
    margin-right: 0.66rem;
  }
`;

const NamePronounce = styled.span`
  position: relative;

  :before {
    content: '';
    width: 100%;
    border-bottom: dotted 4px #2ea75e;
    display: inline-block;
    position: absolute;
    left: 0;
    height: 12px;
    bottom: -1rem;
  }
`;

const Article = styled.article`
  max-width: 44rem;
  text-align: left;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;

  h3 {
    margin-bottom: 2.66rem;
  }

  p, p[data-br] + div {
    font-size: 1.16rem
    line-height: 1.66em;
    color: #565656;
  }

  p a {
    color: #40729A;
  }
`;

const SectionSeparator = styled.section`
  margin-top: 5rem;
  margin-bottom: 5rem;
  border-top: solid 1px #e0e0e0;
`;

const NameExplanationBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  max-width: 30rem;
  left: 0;
  right: 0;
  margin: auto;
  margin-top: 1rem;

  h3 {
    margin: 0;
  }
`;

const NameExplanationBox2 = styled(NameExplanationBox)`
  justify-content: space-between;
`;

const NameExplanationWrapper = styled.div`
  display: block;
  background: #f9f9f9;
  padding: 2rem;
  border-radius: 1rem;
  margin-top: 2.33rem;
  padding-bottom: 3.33rem;
`;
const PhotosBy = styled.p`
  margin-top: 50px;
  font-size: 0.875rem;
  color: #595959;
`;

class Root extends Component {
  render() {
    const isSignedIn = AuthApi.checkSession();

    if (isSignedIn) {
      return <Dashboard />;
    }

    return (
      <AppLayout>
        <ModuleContainer center>
          <section>
            <h2 style={{ marginTop: '0.8rem' }}>Do you speak <NamePronounce data-tip="To communicate using Images (pronounce as /i:mgliʃ/)">imglish</NamePronounce>?</h2>
            <Subtitle>Imglish is a website helping you to communicate with people using just images. It's designed for creatives by creatives to help them organize and share their work. Create your online portfolio in just minutes, drag & drop files anywhere, pause upload whenever you need, share albums with one click - it’s all easy to use. Try me today!</Subtitle>
            <ButtonBox>
              <Link to={Routes.SignUpRoutePath} className="button" style={{ marginRight: 0, color: '#fff' }}>
                Join imglish beta for free
              </Link>
            </ButtonBox>
            <Link to={Routes.SignInRoutePath} style={{ color: '#929292' }}>
              Already have an account? Sign in here <i style={{ position: 'relative', top: '3px', left: '-2px' }} className="material-icons">arrow_forward</i>
            </Link>
          </section>
          <br />
          <br />
          <section style={{ display: 'flex', justifyContent: 'space-between', width: '100vw', left: '-2rem', position: 'relative' }}>
            <PhotoBar width={'calc((100% / 3) - 16px)'} src={JuliaPhoto1} style={{ transform: 'translateX(-2.5rem) scale(0.9)' }} />
            <PhotoBar width={'calc((100% / 3) - 16px)'} src="https://pro2-bar-s3-cdn-cf5.myportfolio.com/6385dd6b-24fb-4e6e-a6d2-352cfe060a36/6b408590-2f77-46ba-b005-61e062edef53_rw_600.png?h=db392179280a9f88ae6bc19df1d28850" style={{ transform: 'scale(1.1)' }} />
            <PhotoBar width={'calc((100% / 3) - 16px)'} src="https://pro2-bar-s3-cdn-cf.myportfolio.com/6385dd6b-24fb-4e6e-a6d2-352cfe060a36/13f5e6fe-2e72-46f9-8c4c-6c41dd68bf06_rw_600.png?h=d01966e0e19d35510aebc2713dea5789" style={{ transform: 'translateX(2.5rem) scale(0.9)' }} />
          </section>
          <PhotosBy>Photos by <a href="https://juliasabiniarz.com/photography" target="_blank">Julia Sabiniarz</a></PhotosBy>
          <section>
            <Article>
              <h3>About imglish</h3>
              <p>
                Imglish has begun as a small in-house project to speed up the process of sharing photos with people we worked with. We were using external tools, which not always were enougth for us, or we were forced to combine several tools to achieve our expectations. That was the reason why we decided to create imglish - <strong>a super simple tool to share and store photos</strong>.
              </p>
              <h4>Is imglish free?</h4>
              <p data-br />
              <div>
                Imglish is completely free to use with your 2GB of storage.
                <ul>
                  <li>Upload unlimited number of your Full Quality Photos along free 2GB storage,</li>
                  <li>Create unlimited number of Albums,</li>
                  <li>Share your Albums in simple and professional way. <Link target="_blank" to="/newfinal/new_final_website">See example →</Link></li>
                </ul>
              </div>
              <br />
              <ButtonBox style={{ textAlign: 'center' }}>
                <Link to={Routes.SignUpRoutePath} className="button" style={{ marginRight: 0, color: '#fff' }}>
                  Join imglish beta for free
                </Link>
              </ButtonBox>
              <br />
              {
              // <h4>What's Premium?</h4>
              // <p data-br />
              // <div>
              //   imglish Premium includes all functionalities of imglish free and:
              //   <ul>
              //     <li>Get as many storage space as you need,</li>
              //     <li>Get your own free portfolio website,</li>
              //     <li>Connect or request your own personal domain,</li>
              //     <li>Share Albums with your logotype</li>
              //   </ul>
              // </div>
              // <br />
              // <div>
              //   <Link to={Routes.SignUpRoutePath} className="button" style={{ marginRight: 0, backgroundColor: '#a7872d', color: '#fff' }}>
              //   Request imglish Premium
              //   </Link>
              // </div>
              }
            </Article>
          </section>
          <section>
            <section style={{ display: 'flex', justifyContent: 'space-between', width: '100vw', left: '-2rem', position: 'relative' }}>
              <PhotoBar width={'calc((100% / 3) - 16px)'} src="https://pro2-bar-s3-cdn-cf6.myportfolio.com/6385dd6b-24fb-4e6e-a6d2-352cfe060a36/04103265-f942-486c-9755-90c6215003f9_rw_600.png?h=d15428354ce5be1ec69d4e828ccc540e" style={{ transform: 'translateX(-2.5rem) scale(0.9)' }} />
              <PhotoBar width={'calc((100% / 3) - 16px)'} src="https://pro2-bar-s3-cdn-cf2.myportfolio.com/6385dd6b-24fb-4e6e-a6d2-352cfe060a36/d758de15-c169-40b0-8c1a-832ffa568017_rw_600.png?h=fdbc5b19a237d9689c2cc03df40848d1" style={{ transform: 'scale(1.1)' }} />
              <PhotoBar width={'calc((100% / 3) - 16px)'} src="https://pro2-bar-s3-cdn-cf1.myportfolio.com/6385dd6b-24fb-4e6e-a6d2-352cfe060a36/93e6b0c9-c6c6-479d-85d9-92affb58ea9b_rw_600.png?h=53f2a0f6d902588a5a0e6e2706380b4a" style={{ transform: 'translateX(2.5rem) scale(0.9)' }} />
            </section>
          </section>
          <PhotosBy>Photos by <a href="https://juliasabiniarz.com/photography" target="_blank">Julia Sabiniarz</a></PhotosBy>
          <section>
            <Article>
              <h3>Why it's named imglish?</h3>
              <p>
                We believe imglish is a place where people tell stories with images and that's why the word imglish is a combination of 2 words:
              </p>
              <NameExplanationWrapper>
                <NameExplanationBox>
                  <h3>IMG</h3>
                  <span>+</span>
                  <h3>LISH</h3>
                </NameExplanationBox>
                <NameExplanationBox2>
                  <span style={{ width: '50%', marginRight: '30px' }}>the shortcut of the word Image</span>
                  <span style={{ width: '50%', marginLeft: '30px' }}>the end of many names of languages, i.e. English, Polish, Spanish </span>
                </NameExplanationBox2>
              </NameExplanationWrapper>
              <br />
              <br />
              <ButtonBox style={{ textAlign: 'center' }}>
                <Link to={Routes.SignUpRoutePath} className="button" style={{ marginRight: 0, color: '#fff' }}>
                  Join imglish beta for free
                </Link>
              </ButtonBox>
            </Article>
          </section>
          <SectionSeparator />
          <p style={{ textAlign: 'center' }}>Read more about <strong>imglish</strong> here: <a style={{ color: '#929292' }} href="https://szymon.kkwm.pl/" target="_blank" rel="noopener noreferrer">https://szymon.kkwm.pl</a></p>
          <ReactTooltip effect="solid" />
          <img src={LogoFooter} style={{ width: '160px', marginBottom: '-70px' }} />
        </ModuleContainer>
      </AppLayout>
    );
  }
}

export default withRouter((Root));
