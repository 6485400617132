// ####### Declarations ##########

import React from 'react';
import PropTypes from 'prop-types';
import AuthApi from 'core/api/auth';

import { isFunction } from 'lodash';
import { Link } from 'react-router-dom';
import { LinePlaceholder } from '../../../modules/modules.styled';
import { CreateReduxComponent } from '../../../self/component';
import { dConnect } from '../../../shared/utils/helpers';

import Dropdown, { MenuItem, DropdownMenu } from '@trendmicro/react-dropdown';
import styled from 'styled-components';

import * as UISC from '../../components.styled';
import Routes from '../../../modules/routes';
import App from '../../../core/app';
import GlobalStatus from '../../globalStatus/globalStatus';
import { RESET_APP_STATE } from '../../../constants';

// ########### Composing Styles ###########
const AppLayout = styled.section`
  padding: 0;
  box-sizing: border-box;
  min-height: 100vh;
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
`;
const Header = styled.header`
  border-bottom: solid 1px #e4e4e4;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  // box-shadow: 0 4px 12px rgba(0,0,0,0.08), 0 0 1px rgba(1,0,0,0.1);
`;
const ImglishLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
`;
const Main = styled.main`
  // margin-top: 80px;
  padding: 2rem;
`;
const Footer = styled.footer`
  padding: 1rem 1.33rem;
  margin-top: 5rem;
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #969696;
`;
const LinkMenuItem = styled(MenuItem)`
  > [role="menuitem"] {
    padding: 0 !important;

    > a {
      width: 100%;
      display: block;
      padding: 4px 16px;
    }
  }
`;
const Small = styled.small`
  text-transform: none;
`;
const SearchBar = styled.div`
  width: 100%;
  background: #eee;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  margin-right: 20px;
  margin-left: 0px;
`;
const SearchIcon = styled.i`
  padding: 9px 9px 9px 14px;
  color: #adadad;
  font-weight: 400;
  font-size: 22px;
  position: relative;
  top: 2px;
`;
const SearchBox = styled.input`
  width: 100%;
  height: 42px;
  background: transparent;
  margin-bottom: 0;
  border: none;
  outline: none;
  padding: 15px 18px 15px 0;
  font-size: 15px;
  color: #333333;
`;

// ########### Composing view ###########
class DefaultLayout extends CreateReduxComponent {
  constructor(props) {
    super(props);

    this.state = {
    };

    this.getHeader = this.getHeader.bind(this);
    this.getFooter = this.getFooter.bind(this);
    this.logout = this.logout.bind(this);
  }

  closeMenuItem() {
    document.body.click();
  }

  showUserAvatar() {
    const { details } = this.props.app.user;
    if (details.file) {
      return (
        <UISC.UserAvatar
          style={{
            backgroundImage: `url(${details.file.path})`
          }}
        />
      );
    }

    return (
      <UISC.UserAvatar style={{ background: '#e8e8e8' }}>
        <i className="material-icons">person</i>
      </UISC.UserAvatar>
    );
  }

  getHeader() {
    const { first_name, last_name } = this.props.app.user.details;

    const isSignedIn = AuthApi.checkSession();
    const name = `${first_name} ${last_name}`;

    return (
      <Header>
        <Link to={Routes.RootRoutePath}>
          <ImglishLogo>
            <UISC.BrandSygnet>
              <UISC.BrandSygnetDot />
              <UISC.BrandSygnetBase />
            </UISC.BrandSygnet>
            <UISC.BrandName>imglish</UISC.BrandName>
          </ImglishLogo>
        </Link>
        {
          // <SearchBar>
          // <SearchIcon className="material-icons">search</SearchIcon>
          // <SearchBox placeholder="Search on imglish" />
          // </SearchBar>
        }
        <UISC.ActionsBar>
          {
            isSignedIn && (
              <Dropdown>
                <Dropdown.Toggle
                  btnStyle="link"
                  noCaret
                >
                  <UISC.UserInfoBox>
                    {this.showUserAvatar()}
                    <span>{name || <React.Fragment><LinePlaceholder style={{ width: '8rem', height: '0.66rem' }} /></React.Fragment>}</span>
                  </UISC.UserInfoBox>
                </Dropdown.Toggle>
                <DropdownMenu style={{ width: '100%' }}>
                  <LinkMenuItem><Link onClick={this.closeMenuItem} to={Routes.ProfilePath}>My Profile</Link></LinkMenuItem>
                  <LinkMenuItem><Link onClick={this.closeMenuItem} to={Routes.DashboardRoutePath}>My Albums</Link></LinkMenuItem>
                  <LinkMenuItem><Link onClick={this.closeMenuItem} to={Routes.SettingsPath}>Settings</Link></LinkMenuItem>
                  <MenuItem divider />
                  <MenuItem
                    onSelect={this.logout}
                  >
                    Logout
                  </MenuItem>
                </DropdownMenu>
              </Dropdown>
            )
          }
          {
            !isSignedIn && (
              <Link to={Routes.SignInRoutePath}>Sign In</Link>
            )
          }
        </UISC.ActionsBar>
      </Header>
    );
  }

  getFooter() {
    return (
      <Footer style={{ textAlign: 'center' }}>Copyright by © CoyaPonk · 2018 - 2019 · <Small>v.{process.env.REACT_APP_VERSION}</Small></Footer>
    );
  }

  async logout() {
    await AuthApi.logout();

    this.dispatch({ type: RESET_APP_STATE });
    // this.props.history.push('/signin');
  }

  render() {
    const { children } = this.props;

    console.log(this);

    return (
      <AppLayout>
        {this.getHeader()}
        <GlobalStatus />
        <Main>{isFunction(children) ? children(this) : children}</Main>
        {this.getFooter()}
      </AppLayout>
    );
  }
}

DefaultLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default dConnect(DefaultLayout);
