import { ROUTE_PATH as RootRoutePath } from './root/route-path';
import { ROUTE_PATH as AlbumRoutePath } from './album/route-path';
import { ROUTE_PATH as DashboardRoutePath } from './dashboard/route-path';
import { ROUTE_PATH as SettingsPath } from './settings/route-path';
import { ROUTE_PATH as AuthRoutePath } from './auth/route-path';
import { ROUTE_PATH as SignInRoutePath } from './auth/signIn/route-path';
import { ROUTE_PATH as SignUpRoutePath } from './auth/signUp/route-path';
import { ROUTE_PATH as ForgotPath } from './auth/forgotPassword/route-path';
import { ROUTE_PATH as ProfilePath } from './profile/route-path';
import { ROUTE_PATH as AlbumSettingsPath } from './album/views/settings/route-path';


export default {
  RootRoutePath,
  AlbumRoutePath,
  SignInRoutePath,
  DashboardRoutePath,
  SignUpRoutePath,
  SettingsPath,
  ForgotPath,
  AuthRoutePath,
  ProfilePath,
  AlbumSettingsPath
};
