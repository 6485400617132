// ####### Declarations ##########
import { Component, PureComponent } from 'react';
import { isFunction, isObject, forEach } from 'lodash';

// ####### CreateReduxComponent ##########
class CreateReduxComponent extends Component {
  constructor(props) {
    super(props);

    if (isFunction(props.dispatch)) {
      this.dispatch = (action) => {
        props.dispatch(action);
      };
    } else if (isObject(props.dispatch)) {
      this.dispatch = (action) => {
        props.dispatch.default(action);
      };

      forEach(props.dispatch, (fn, key) => {
        Object.defineProperty(this.dispatch, key, { value: fn });
      });
    }
  }
}

// ####### CreateComponent ##########
class CreateComponent extends Component {
  constructor(props) {
    super(props);

    this.CONSTS = {
      ACTION: {
        READY: 'READY'
      }
    };
  }

  reducer(state, action) {
    switch (action.type) {
      case this.CONSTS.ACTION.READY:
        return {
          ...state,
          ...action.payload,
          ready: true
        };
      default:
        return state;
    }
  }

  dispatch(action = { type: null, payload: {} }) {
    if (this.reducer && isFunction(this.reducer)) {
      this.setState((prevState) => {
        let nextAction = action;

        if (isFunction(nextAction)) {
          nextAction = nextAction(prevState);
        }

        return this.reducer(prevState, nextAction);
      });
    } else {
      throw new SyntaxError('Please provide reducer function to use this functionality!');
    }
  }
}

// ####### CreatePureComponent ##########
class CreatePureComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.CONSTS = {
      ACTION: {
        READY: 'READY'
      }
    };
  }

  reducer(state, action) {
    switch (action.type) {
      case this.CONSTS.ACTION.READY:
        return {
          ...state,
          ...action.payload,
          ready: true
        };
      default:
        return state;
    }
  }

  dispatch(action = { type: null, payload: {} }) {
    if (this.reducer && isFunction(this.reducer)) {
      this.setState((prevState) => {
        let nextAction = action;

        if (isFunction(nextAction)) {
          nextAction = nextAction(prevState);
        }

        return this.reducer(prevState, nextAction);
      });
    } else {
      throw new SyntaxError('Please provide reducer function to use this functionality!');
    }
  }
}

// ####### Export ##########
export default CreateComponent;
export { CreatePureComponent, CreateReduxComponent };
