import React from 'react';
import styled from 'styled-components';
import { Draggable, Droppable } from 'react-beautiful-dnd';

import Task from './task';

const TasksContainer = styled.div`
  // display: block;
  // width: 100%;
  min-height: 100px;
  // border: solid 1px; 

  border-radius: 7px;
  padding-top: 1px;
  border: solid 3px #fff;
  display: block;
  background: #eee;

  display: ${props => (props.direction === 'horizontal' ? 'flex' : 'block')};
  background: ${props => (props.isDraggingOver ? '#e8f7e9' : '#fff')};
`;
const ColumnContainer = styled.div`
  // border: solid 1px #444;
  // padding: 10px;
  // background: #fff;
  
  border-radius: 7px;
  padding-top: 1px;
  border: solid 3px #fff;
  display: block;
  background: #fff;
  
  margin-bottom: 10px;
`;

class InnerTask extends React.PureComponent {
  render() {
    const totalColumnSize = this.props.tasks.reduce((r, n, i) => {
      const ratio = parseFloat((n.thumbnail.max.meta.width / n.thumbnail.max.meta.height * 100).toFixed(4));

      this.props.tasks[i].ratio = ratio;

      return r + ratio;
    }, 0);

    return this.props.tasks.map((task, index) => {
      const size = parseFloat((task.ratio / totalColumnSize * 100).toFixed(4));
      const width = parseFloat((458 * size / 100).toFixed(2));
      return <Task key={task.id} task={task} index={index} column={this.props.column} width={width} isDrop={this.props.isDrop} />;
    });
  }
}

class Column extends React.Component {
  render() {
    return (
      <Draggable draggableId={this.props.column.id} index={this.props.index}>
        {HOCprovided => (
          <ColumnContainer
            {...HOCprovided.draggableProps}
            innerRef={HOCprovided.innerRef}
            // style={this.props.styles}
          >
            <div {...HOCprovided.dragHandleProps} style={{ color: '#a5a5a5' }}>
              <i className="material-icons">drag_indicator</i>
            </div>
            <Droppable droppableId={this.props.column.id} direction={this.props.column.direction} type="task">
              {(provided, snapshot) => (
                <TasksContainer
                  innerRef={provided.innerRef}
                  direction={this.props.column.direction}
                  {...snapshot}
                  {...provided.droppableProps}
            >
                  <InnerTask tasks={this.props.tasks} column={this.props.column.id} isDrop={this.props.isDrop} />
                  {provided.placeholder}
                </TasksContainer>
              )}
            </Droppable>
          </ColumnContainer>
        )}
      </Draggable>
    );
  }
}

export default Column;
