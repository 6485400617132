import * as constants from '../constants';
import produce from 'immer';

const initialState = {
  details: {}
};

export const user = (state = initialState, action) => produce(state, (draft) => {
  switch (action.type) {
    case constants.INIT_APP_STATE: {
      draft.details = action.payload.user;
      break;
    }
    case constants.SET_USER_DATA: {
      draft.details = action.payload;
      break;
    }
    case constants.RESET_APP_STATE: {
      return initialState;
    }
  }
});
