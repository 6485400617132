// ####### Declarations ##########
import React from 'react';
import App from '../../../core/app';
import AuthApi from 'core/api/auth';
import UserApi from '../../../core/api/APIsettings';

import { Link } from 'react-router-dom';
import { BrandSygnet, BrandSygnetBase, BrandSygnetDot } from 'components/components.styled';
import { CreateReduxComponent } from '../../../self/component';
import { setUserData } from '../../../actions/user';
import { dConnect } from '../../../shared/utils/helpers';

import Form from 'shared/ui/form/form';
import Input from 'shared/ui/input/input';

// ########### Composing view ###########
class SignIn extends CreateReduxComponent {
  constructor(props) {
    super(props);

    this.defaultState = {
      email: '',
      password: ''
    };

    this.onSuccess = this.onSuccess.bind(this);
  }

  async onSuccess() {
    const res = await UserApi.get();
    const userData = res.message;

    this.dispatch(setUserData(userData));
  }

  onError() {
    App.get('globalStatus').setContextState({
      status: {
        mode: 'toast',
        type: 'warning',
        message: "Meh... 😔 The login or password you've provided doesn't match any account. Please check it 👇",
        time: 5000,
        noScroll: true
      }
    });
  }

  render() {
    return (
      <Form api={AuthApi.login} onSuccess={this.onSuccess} onError={this.onError} defaultState={this.defaultState}>
        {
          actions => (
            <div className="formDiv">
              <BrandSygnet styled={`
                transform: scale(1.5);
                left: 0;
                right: 0;
                margin: auto;
                margin-bottom: -2rem;
                margin-top: 4rem;
              `}>
                <BrandSygnetDot styled={`
                  background: #f3f3f3;
                `} />
                <BrandSygnetBase styled={`
                  background: #f3f3f3;
                `} />
              </BrandSygnet>
              <h3>Welcome to imglish</h3>
              <p style={{ fontSize: '1.16rem', marginTop: '-0.33rem', color: '#565656' }}>Sign in using your email and password</p>
              <br />
              <Input name="email" type="email" placeholder="Email" value={actions.state.email.value} valid={actions.state.email.valid} onChange={event => actions.handleChange(event)} />
              <Input name="password" type="password" placeholder="Password" value={actions.state.password.value} valid={actions.state.password.valid} onChange={event => actions.handleChange(event)} />
              <Input type="submit" value="Sign In" className="button" disableAfterClick style={{ width: '100%' }} />
              <Link className="link" to="/signup">
                Don't have an account? Sign up →
              </Link>
              <Link className="link" to="/resetpassword">
                Forgot password? Click here →
              </Link>
              <br />
            </div>
          )
        }
      </Form>
    );
  }
}

// ########### Export ###########
export default dConnect(SignIn);
