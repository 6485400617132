import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { merge } from 'lodash';

const CONSTS = {
  ANIMATED_CLASS_NAME: 'animated',
  FADE_IN_UP_CLASS_NAME: 'fadeInUp'
};

class Album extends Component {
  constructor(props) {
    super(props);

    this.getClassName = this.getClassName.bind(this);

    this.state = {
      className: this.getClassName(),
      style: {}
    };
  }

  getClassName() {
    const { className, animate } = this.props;

    const classes = className.split(' ');

    // if (animate) {
    //   classes.push(CONSTS.ANIMATED_CLASS_NAME, CONSTS.FADE_IN_UP_CLASS_NAME);
    // }

    return classes.join(' ');
  }

  componentWillUnmount() {
    clearTimeout(this.animationID);
  }

  componentDidMount() {
    // const { animate, index } = this.props;

    // if (animate) {
    //   this.animationID = setTimeout(() => {
    //     this.setState(prevState => ({
    //       className: prevState.className.replace(CONSTS.ANIMATED_CLASS_NAME, '').replace(CONSTS.FADE_IN_UP_CLASS_NAME, '').trim(),
    //       style: {
    //         transform: 'none',
    //         opacity: 1
    //       }
    //     }));
    //   }, 1000 + (1000 / 60 * index));
    // }
  }

  render() {
    const { nodeKey, children, props } = this.props;
    const { style: stateStyle, className } = this.state;
    const { style: propsStyle, ...rest } = props;

    return (
      <li className={className} key={'node-' + nodeKey} style={merge(propsStyle, stateStyle)} {...rest}>
        {children}
      </li>
    );
  }
}

Album.defaultProps = {
  className: 'album',
  props: {},
  animate: true,
  index: 0,
  children: null
};

Album.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf([
      PropTypes.string
    ])
  ]),
  animate: PropTypes.bool,
  props: PropTypes.object,
  nodeKey: PropTypes.string.isRequired,
  index: PropTypes.number
};

export default Album;
