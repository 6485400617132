// ####### Declarations ##########
import React from 'react';
import styled from 'styled-components';

import { dConnect } from '../../../../shared/utils/helpers';

// ########### Styled Components ############
const Profession = styled.h3`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  margin: 0;
  letter-spacing: 2px;
  font-size: 0.875rem;
  font-weight: 600;
  color: #757575;
`;
const Name = styled.h2`
  margin: 0;
  font-weight: 800;

  display: flex;
  align-items: center;
`;
const AboutUser = styled.div`
  margin-bottom: 19px;
`;
const P = styled.p`
  text-align: center;
  font-size: 1.2rem;
  color: #767676;
  width: 500px;
  left: 0;
  right: 0;
  margin: auto;
  margin-top: 10px;
`;
const ApprovedBadge = styled.div`
  display: inline-block;
  background: #2da75e;
  color: #fff;
  border-radius: 80px;
  height: 22px;
  width: 22px;
  text-align: center;
  margin-left: 6px;
  user-select: none;

  > i {
    font-size: 14px;
    position: relative;
    top: 4px;
    left: 0px;
    font-weight: 900;
  }
`;
export const Approved = () => (
  <ApprovedBadge>
    <i className="material-icons">done</i>
  </ApprovedBadge>
);

// ########### Composing view ###########
const Overview = (props) => {
  const a = '';

  return (
    <span>
      <AboutUser className="animated fadeIn">
        <Profession style={{ justifyContent: 'center' }}>Photographer <Approved /></Profession>
        <Name style={{ justifyContent: 'center' }}>{props.app.user.details.first_name} {props.app.user.details.last_name}</Name>
        <P>Julia have great range of skills to offer. Browse by neatly curated categories:</P>
      </AboutUser>
      <div style={{ backgroundImage: 'url(https://juliasabiniarz.com/storage/app/uploads/public/5c0/31a/66c/thumb_159_480_640_0_0_auto.png)' }} />
    </span>
  );
};

// ########### Export ###########
export default dConnect(Overview);
