// ####### Declarations ##########

import React from 'react';
import Api from './api';

import { Link } from 'react-router-dom';
import { concat, map, orderBy } from 'lodash';
import { toSize, dConnect } from '../../shared/utils/helpers';
import { getRoute as getAlbumRoute } from '../album/route-path';
import { CreateReduxComponent } from '../../self/component';
import { setUsersAlbums } from '../../actions/storage';

import './dashboard.css';

import Authenticate from 'components/authenticate/authenticate';
import AppLayout from 'components/layouts/default/default';

import AlbumOld from './partials/album/album';
import Album from '../../shared/ui/album/album';
import * as Style from './dashboard.styled';

import UploadService from '../../services/UploadService/UploadService';
import styled from 'styled-components';

// ########### Styled Components ###########
const ViewBox = styled.div`
  box-sizing: border-box;
  color: #111;
`;
const AlbumsBox = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
  grid-gap: 1.5rem;
  // padding: 1.5rem;
  width: 100%;

  @media (max-width: 365px) {
    grid-template-columns: 1fr;
    padding: 1rem;
  }

  @media (min-width: 366px) and (max-width: 560px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: 561px) and (max-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  @media (min-width: 1024px) and (max-width: 1280px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }

  @media (min-width: 1281px) and (max-width: 1560px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }

  @media (min-width: 1561px) and (max-width: 1920px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  @media (min-width: 1921px) and (max-width: 2300px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  @media (min-width: 2301px) and (max-width: 2560px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  @media (min-width: 2561px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
`;
const Title = styled.h1`
  font-size: 3rem;
  font-weight: 800;
  // padding: 0 17px;
  // padding-top: 30px;
`;
const SubTitle = styled.h3`
  font-size: 1.33rem;
  font-weight: 500;
  // padding: 0 17px;
  margin-top: -25px;
  color: #636363;
  margin-bottom: 40px;
`;

// ########### Composing view ###########

class Dashboard extends CreateReduxComponent {
  constructor(props) {
    super(props);

    this.state = {
      ready: false // !!props.app.storage.albums.length
    };

    this.getAlbum = this.getAlbum.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.getAlbums = this.getAlbums.bind(this);
    this.getGalleryView = this.getGalleryView.bind(this);
    this.getAddNewAlbumButton = this.getAddNewAlbumButton.bind(this);

    this.fuse = new UploadService();
    this.fuse.registerSubscriberPopulator('Dashboard', this.fetchData);
  }

  getAddNewAlbumButton() {
    return this.getAlbum({
      className: 'album-new album-flat',
      key: 'add-new-album',
      content: (
        <Link to={getAlbumRoute({ id: 'new' })}>
          <i className="material-icons">add</i>
          Add new album
        </Link>
      )
    });
  }

  getGalleryView(albums = null) {
    const { ready } = this.state;
    const albumsSize = toSize(this.props.app.user.details.usermeta.size);
    const albumsNumber = this.props.app.storage.albums.length;

    return (
      <React.Fragment>
        <header className="section-header">
          <Title>Albums</Title>
          <SubTitle>{ready ? `You have ${albumsNumber} albums (${albumsSize})` : 'Loading your albums...'}</SubTitle>
        </header>
        <AlbumsBox>
          {
            this.getAddNewAlbumButton()
          }
          { albums }
        </AlbumsBox>
      </React.Fragment>
    );
  }

  getAlbum(pms = { className: [], key: '', content: null, props: {}, animate: true }) {
    const { className, content, key, ...rest } = pms;
    const albumClasses = concat('album', className).join(' ');

    return (
      <AlbumOld className={albumClasses} key={key} nodeKey={key} {...rest}>
        {content}
      </AlbumOld>
    );
  }

  getAlbums() {
    const { ready } = this.state;
    const { albums } = this.props.app.storage;

    if (!ready) {
      return this.getGalleryView([
        this.getAlbum({
          key: 'album-loader-1',
          className: 'album-loader album-flat',
          props: {
            style: {
              cursor: 'default'
            }
          }
        }),
        this.getAlbum({
          key: 'album-loader-2',
          className: 'album-loader album-flat',
          props: {
            style: {
              cursor: 'default'
            }
          }
        }),
        this.getAlbum({
          key: 'album-loader-3',
          className: 'album-loader album-flat',
          props: {
            style: {
              cursor: 'default'
            }
          }
        }),
        this.getAlbum({
          key: 'album-loader-4',
          className: 'album-loader album-flat',
          props: {
            style: {
              cursor: 'default'
            }
          }
        })
      ]);
    }

    if (!albums.length) {
      const placeholders = [];

      for (let i = 0; i < 4; i++) {
        placeholders.push(
          this.getAlbum({
            className: 'album-flat',
            key: 'album-placeholder-' + i,
            props: {
              style: {
                cursor: 'default',
                backgroundColor: '#f7f7f7',
                justifyContent: 'center',
                alignItems: 'center'
              }
            },
            content: (
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
                <i className="material-icons" style={{
                  color: '#dedede',
                  margin: 0
                }}>photo_album
                </i>
                <p style={{
                  marginBottom: '0',
                  fontSize: '0.875rem',
                  color: '#c1c1c1',
                  textAlign: 'center',
                  marginTop: '1.33rem'
                }}>Your album will appear here
                </p>
              </div>

            )
          })
        );
      }

      return (
        <div>
          <header className="section-header">
            <Style.TopSectionTitle>Albums</Style.TopSectionTitle>
            <h5 className="light mt0" style={{ color: '#7b7b7b' }}>Drop your photos anywhere or click below to start <span role="img" aria-label="strong">👇</span></h5>
          </header>
          <ul className="albums-list">
            {this.getAddNewAlbumButton()}
            {placeholders}
          </ul>
        </div>
      );
    }

    return this.getGalleryView(map(orderBy(albums, 'created', 'desc'), (album) => {
      const { id, photo, photos, created, name: title } = album;

      const albumProps = {
        photos,
        title,
        date: created.replace(' ', 'T') + 'Z',
        cover: photo && photo.thumbnail.max.path,
        placeholder: photo && photo.thumbnail.min.path,
        color: photo && photo.color
      };

      return (
        <Link key={id} to={getAlbumRoute({ id })}>
          <Album {...albumProps} />
        </Link>
      );
    }));
  }

  async fetchData() {
    const albums = await Api.getAllAlbums();

    this.dispatch(setUsersAlbums(albums));
    this.setState({ ready: true });
  }

  async componentDidMount() {
    if (!this.state.ready) {
      this.fetchData();
    }
  }

  componentWillUnmount() {
    this.fuse.unregisterSubscriberPopulator('Dashboard');
  }

  render() {
    return (
      <AppLayout>
        <ViewBox className={!this.props.app.storage.albums.length && 'empty'}>
          {this.getAlbums()}
        </ViewBox>
      </AppLayout>
    );
  }
}

// ########### Export ###########
export default dConnect(Dashboard);

// const addNewSnapAlbum = (
//   this.getAlbum({
//     className: 'album-new album-flat',
//     key: 'add-new-album',
//     content: (
//       <Link style={{ pointerEvents: 'none' }} to={getAlbumRoute({ id: 'new' })}>
//         <i className="material-icons">add</i>
//         Add new snap album
//       </Link>
//     )
//   })
// );

// SNAP ALBUMS

// <div>
//   <header className="section-header">
//     <TopSectionTitle>Snap Albums</TopSectionTitle>
//     <h5 className="light mt0" style={{ color: '#7b7b7b' }}>
//       {
//         this.state.ready ? 'You have 0 snap albums (0 MB)' : 'Loading your albums...'
//       }
//     </h5>
//   </header>
//   <ul className="albums-list">{addNewSnapAlbum}</ul>
// </div>
