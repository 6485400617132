import * as constants from '../constants';
import produce from 'immer';

const initialState = {
  albums: [],
  albumsDetails: {}
};

export const storage = (state = initialState, action) => produce(state, (draft) => {
  switch (action.type) {
    case constants.INIT_APP_STATE: {
      draft.albums = action.payload.albums;
      break;
    }
    case constants.SET_USER_ALBUMS: {
      draft.albums = action.payload;
      break;
    }
    case constants.SET_ALBUM_DETAILS: {
      const { album, photos } = action.payload;
      const { id, name: title } = album;

      draft.albumsDetails[id] = { id, album, title, photos };

      break;
    }
    case constants.RESET_APP_STATE: {
      return initialState;
    }
  }
});
