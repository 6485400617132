import Config from 'config/config';
import { setHeaders } from 'shared/utils/url';

const checkURI = (pms = {
  domain: '',
  uri: ''
}) => fetch(Config.serverURL + '/api/check', {
  method: 'POST',
  headers: setHeaders({
    auth: false
  }),
  body: JSON.stringify(pms)
});

export default {
  checkURI
};
