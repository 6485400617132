import Config from 'config/config';
import { setHeaders } from 'shared/utils/url';

// import { sample, random } from 'lodash';
// import { delayApi } from '../../shared/utils/helpers';

// const images = [
//   'http://juliasabiniarz.com/storage/app/uploads/public/5a3/81c/b8d/5a381cb8d67da235350712.jpg',
//   'http://juliasabiniarz.com/storage/app/uploads/public/5b1/070/9d6/5b10709d66b70051101839.jpg',
//   'http://juliasabiniarz.com/storage/app/uploads/public/5a3/81c/be0/5a381cbe0aa6c986507825.png',
//   'http://juliasabiniarz.com/storage/app/uploads/public/5a3/828/2db/5a38282dbbad8378586743.jpg',
//   'http://juliasabiniarz.com/storage/app/uploads/public/5b0/31e/fcf/5b031efcf31a7010555828.jpg',
//   'http://juliasabiniarz.com/storage/app/uploads/public/5b0/31e/fc7/5b031efc7f91d138354581.jpg',
//   'http://juliasabiniarz.com/storage/app/uploads/public/5a3/828/2f9/5a38282f94c49850441003.jpg',
//   'http://juliasabiniarz.com/storage/app/uploads/public/5a3/828/4d0/5a38284d0216f710538889.jpg',
//   'http://juliasabiniarz.com/storage/app/uploads/public/5b1/06e/99f/5b106e99f1d19695271197.jpg',
//   'http://juliasabiniarz.com/storage/app/uploads/public/5b0/31e/fb1/5b031efb1da27875359219.jpg',
//   'http://juliasabiniarz.com/storage/app/uploads/public/5af/9ae/ee1/5af9aeee15fac222748560.jpg',
//   'http://juliasabiniarz.com/storage/app/uploads/public/5b0/564/603/5b05646035096437391767.png',
//   'http://juliasabiniarz.com/storage/app/uploads/public/5a3/828/01c/5a382801ce555096382149.jpg',
//   'http://juliasabiniarz.com/storage/app/uploads/public/5a3/827/f25/5a3827f25b2cf873217552.jpg',
//   'http://juliasabiniarz.com/storage/app/uploads/public/5a3/828/53b/5a382853b336d905741334.jpg',
//   'http://juliasabiniarz.com/storage/app/uploads/public/5a3/828/261/5a3828261be0a713407906.jpg',
//   'http://juliasabiniarz.com/storage/app/uploads/public/5a3/828/451/5a3828451fa15031247293.jpg',
//   'http://juliasabiniarz.com/storage/app/uploads/public/5a3/828/816/5a38288165669299232610.jpg',
//   'http://juliasabiniarz.com/storage/app/uploads/public/5a6/0ff/4d6/5a60ff4d6e3b9488285733.jpg',
//   'http://juliasabiniarz.com/storage/app/uploads/public/5b1/070/9c8/5b10709c83062797548210.jpg',
//   'http://juliasabiniarz.com/storage/app/uploads/public/5a3/828/12c/5a382812c5300540281326.jpg',
//   'http://juliasabiniarz.com/storage/app/uploads/public/5af/9ae/ef2/5af9aeef21bd9338588794.jpg',
//   'http://juliasabiniarz.com/storage/app/uploads/public/5a3/828/10a/5a382810ae299965848390.jpg',
//   'http://juliasabiniarz.com/storage/app/uploads/public/5ac/fe3/60f/5acfe360f2012629620784.jpg',
//   'http://juliasabiniarz.com/storage/app/uploads/public/5af/44d/234/5af44d2340887039272342.jpg',
//   'http://juliasabiniarz.com/storage/app/uploads/public/5af/44d/222/5af44d222f468744492775.jpg',
//   'http://juliasabiniarz.com/storage/app/uploads/public/5a3/828/73d/5a382873d6886445911608.jpg',
//   'http://juliasabiniarz.com/storage/app/uploads/public/5b0/31e/fc2/5b031efc2b033396670621.jpg',
//   'http://juliasabiniarz.com/storage/app/uploads/public/5b0/31e/fad/5b031efad3108160348274.jpg',
//   'http://juliasabiniarz.com/storage/app/uploads/public/5b1/06e/99a/5b106e99a5605883439706.jpg',
//   'http://juliasabiniarz.com/storage/app/uploads/public/5af/9ae/efa/5af9aeefa0dfb530707491.jpg',
//   'http://juliasabiniarz.com/storage/app/uploads/public/5a3/aca/343/5a3aca3431089026605256.jpg',
//   'http://juliasabiniarz.com/storage/app/uploads/public/5a3/828/86a/5a382886a51a0590307349.jpg',
//   'http://juliasabiniarz.com/storage/app/uploads/public/5a3/828/394/5a3828394f2d7584280448.jpg',
//   'http://juliasabiniarz.com/storage/app/uploads/public/5a3/828/01a/5a382801a3ad0757834906.jpg',
//   'http://juliasabiniarz.com/storage/app/uploads/public/5a3/828/4e8/5a38284e82c86579031064.jpg',
//   'http://juliasabiniarz.com/storage/app/uploads/public/5a3/828/523/5a382852368e1023963615.jpg',
//   'http://juliasabiniarz.com/storage/app/uploads/public/5ac/fe2/475/5acfe247599bd096003273.jpg',
//   'http://juliasabiniarz.com/storage/app/uploads/public/5af/9ae/ef8/5af9aeef8857e470100366.jpg',
//   'http://juliasabiniarz.com/storage/app/uploads/public/5a3/828/6fd/5a38286fde256110309603.jpg',
//   'http://juliasabiniarz.com/storage/app/uploads/public/5ac/fe3/608/5acfe3608cef6537790220.jpg',
//   'http://juliasabiniarz.com/storage/app/uploads/public/5a3/828/66b/5a382866b3cef596865122.jpg',
//   'http://juliasabiniarz.com/storage/app/uploads/public/5b1/06e/9ab/5b106e9abaaec280856202.jpg',
//   'http://juliasabiniarz.com/storage/app/uploads/public/5b1/06e/995/5b106e99577b3882579542.jpg',
//   'http://juliasabiniarz.com/storage/app/uploads/public/5af/9ae/ee8/5af9aeee8b706850519530.jpg',
//   'http://juliasabiniarz.com/storage/app/uploads/public/5a3/828/16c/5a382816cf2aa803406447.jpg',
//   'http://juliasabiniarz.com/storage/app/uploads/public/5b1/070/9ee/5b10709eea134059012595.jpg',
//   'http://juliasabiniarz.com/storage/app/uploads/public/5b0/31e/fb6/5b031efb6317c595843312.jpg',
//   'http://juliasabiniarz.com/storage/app/uploads/public/5a3/828/084/5a3828084ac97158994871.jpg',
//   'http://juliasabiniarz.com/storage/app/uploads/public/5a3/827/f6a/5a3827f6ab9b8881835849.jpg',
//   'http://juliasabiniarz.com/storage/app/uploads/public/5a4/390/826/5a4390826c940431581618.jpg',
//   'http://juliasabiniarz.com/storage/app/uploads/public/5a3/828/407/5a3828407230b146372719.jpg',
//   'http://juliasabiniarz.com/storage/app/uploads/public/5a3/828/213/5a38282132cbb564928290.jpg',
//   'http://juliasabiniarz.com/storage/app/uploads/public/5a3/828/5c3/5a38285c313af874061471.jpg',
//   'http://juliasabiniarz.com/storage/app/uploads/public/5a3/828/67b/5a382867b73aa348663913.jpg',
//   'http://juliasabiniarz.com/storage/app/uploads/public/5a3/ad3/7bf/5a3ad37bf1ce0799431465.jpg',
//   'http://juliasabiniarz.com/storage/app/uploads/public/5a3/ad3/be8/5a3ad3be8bc19179233248.jpg',
//   'http://juliasabiniarz.com/storage/app/uploads/public/5a3/ad4/6d6/5a3ad46d67afb318242716.jpg',
//   'http://juliasabiniarz.com/storage/app/uploads/public/5a3/ad3/7cc/5a3ad37cc7d64993478314.jpg',
//   'http://juliasabiniarz.com/storage/app/uploads/public/5a3/ad4/206/5a3ad420682d0362911284.jpg',
//   'http://juliasabiniarz.com/storage/app/uploads/public/5a3/ad4/56b/5a3ad456b8ce3718354738.jpg',
//   'http://juliasabiniarz.com/storage/app/uploads/public/5a3/ad3/d17/5a3ad3d17127e908416988.jpg',
//   'http://juliasabiniarz.com/storage/app/uploads/public/5a3/ad4/085/5a3ad4085a5ae192983240.jpg'
// ];

// const albumsJSON = [
//   {
//     id: 443,
//     title: 'Oklahoma Pants Avon Practical Soft Pants',
//     color: 'rgb(228,169,35)',
//     files: '94',
//     cover: sample(images),
//     size: 0.74621
//   },
//   {
//     id: 795,
//     title: 'Azure New Jersey',
//     color: 'rgb(239,109,252)',
//     files: '140',
//     cover: sample(images),
//     size: 0.44981
//   },
//   {
//     id: 463,
//     title: 'Skyway withdrawal concept Global',
//     color: 'rgb(134,59,82)',
//     files: '1',
//     cover: sample(images),
//     size: 0.98483
//   }
// ];

const getAllAlbums = () => fetch(Config.serverURL + '/album/list', {
  method: 'GET',
  headers: setHeaders()
}).then(res => res.message);

export default {
  getAllAlbums
};
