// ####### Declarations ##########

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Routes from '../../routes';
import { getRoute } from '../route-path';
import Api from '../api';
import { Status } from '../../../shared/ui/ui';

// ########### Composing Styles ###########

const AlbumNameInput = styled.input`
  width: 100%;
  border: none;
  font-size: 2.66em;
  padding: 1rem;
  font-weight: 800;
  line-height: 1.2;
  margin: 0.33rem -1rem 0.33rem;
  padding-top: 0;
  padding-bottom: 0;
  outline: none;
  transition: all ease-in 0.1s;

  :hover, :focus {
    background: #eeeeee;
  }
`;

// ########### Composing view ###########
class Name extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ready: false,
      title: '',
      id: 0,
      toastProps: null
    };

    this.saveTimeout = 512;
    this.saveTimeoutID = null;
    this.lastClick = null;

    this.inputRef = React.createRef();

    this.updateAlbum = this.updateAlbum.bind(this);
    this.updateAlbumName = this.updateAlbumName.bind(this);
  }

  updateAlbum() {
    const { title: name, id } = this.state;

    const hideToast = () => {
      setTimeout(() => {
        this.setState({
          toastClass: 'animated fadeOutDown'
        });
      }, 2000);

      setTimeout(() => {
        this.setState({
          toastClass: null,
          toastProps: null
        });
      }, 3000);
    };

    if (name) {
      return Api.updateAlbum({ id, name }).then((res) => {
        if (res.success) {
          this.setState({
            toastClass: null,
            toastProps: {
              mode: 'toast',
              noScroll: true,
              type: 'success',
              message: 'Album name changed ☺️'
            }
          }, hideToast);
        } else {
          this.setState({
            toastClass: null,
            toastProps: {
              mode: 'toast',
              noScroll: true,
              type: 'error',
              message: 'Error while changing album name :('
            }
          }, hideToast);
        }
      });
    }
  }

  updateAlbumName(e) {
    this.setState({
      title: e.target.value
    });

    const now = new Date().getTime();

    if (this.lastClick && now - this.lastClick < this.saveTimeout) {
      clearTimeout(this.saveTimeoutID);
    }

    if (e.key === 'Enter') {
      this.updateAlbum();
      e.target.blur();
    } else {
      this.lastClick = now;
      this.saveTimeoutID = setTimeout(this.updateAlbum, this.saveTimeout);
    }
  }

  componentDidMount() {
    const { title, id } = this.props;

    this.setState({ ready: true, id, title });
  }

  render() {
    const { ready, title, id, toastProps, toastClass } = this.state;

    if (!ready) {
      return null;
    }

    return (
      <div style={{ width: '100%' }}>
        <AlbumNameInput
          ref={this.inputRef}
          type="text"
          onChange={this.updateAlbumName}
          // onBlur={this.updateAlbum}
          onKeyUp={this.updateAlbumName}
          value={title}
        />
        <h5 className="light mt0" style={{
          color: '#7b7b7b',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden'
        }}>
          <Link to={Routes.DashboardRoutePath}>Albums</Link>
          <span> / </span>
          <Link to={getRoute({ id })}>{title}</Link>
        </h5>
        {toastProps ? <Status toastClass={toastClass} {...toastProps} /> : null}
      </div>
    );
  }
}

export default Name;
