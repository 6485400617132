import Config from 'config/config';
import { setHeaders } from 'shared/utils/url';

const signUp = pms => fetch(Config.serverURL + '/user/register', {
  method: 'POST',
  headers: setHeaders({
    auth: false
  }),
  body: JSON.stringify(pms)
}).then((res) => {
  if (res.success) {
    console.info('Registred!');
  }

  return res;
});

export default { signUp };
