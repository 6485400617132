import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

import './shared/utils/fetch';
import './styles/index.css';
import './styles/animate.css';

import '@trendmicro/react-buttons/dist/react-buttons.css';
import '@trendmicro/react-dropdown/dist/react-dropdown.css';

import { BrowserRouter as Router } from 'react-router-dom';
import { createStore, combineReducers } from 'redux';
import { Provider } from 'react-redux';

import Modules from './modules/modules';
import Reducers from './reducers/reducers';

const reducers = combineReducers(Reducers);
const store = createStore(reducers);

const Root = () => (
  <Provider store={store}>
    <Router>
      <Modules />
    </Router>
  </Provider>
);


ReactDOM.render(<Root />, document.getElementById('root'));
