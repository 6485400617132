// ####### Declarations ##########

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './default.css';

import { withRouter, Link } from 'react-router-dom';

import styled from 'styled-components';
import AuthApi from 'core/api/auth';

import Routes from '../../../modules/routes';

import GlobalStatus from '../../globalStatus/globalStatus';


// ########### Composing view ###########

const LogoSeparator = styled.div`
  width: 1px;
  height: 30px;
  display: inline-block;
  background: #cccccc;
  border-radius: 0.33rem;
  margin-right: 1rem;
`;

const UserLogo = styled.div`
  width: 30px;
  height: 30px;
  display: inline-block;
  background: #cccccc;
  border-radius: 0.33rem;
  margin-right: 1rem;
  background-size: cover;
  background-position:  50%;
`;

const AppContainer = styled.section`
  padding: 1.67rem 2rem;
  box-sizing: border-box;
  min-height: 100vh;
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);

  @media (max-width: 320px){
    padding: 1rem 1rem
  }
`;

const BrandSygnet = styled.div`
  transform: scale(0.8);
  width: 40px;
  height: 40px;
  display: flex;
  background: #2DA75E;
  border-radius: 7px;
  margin-right: 1rem;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
`;

const BrandSygnetDot = styled.div`
  width: 10px;
  height: 10px;
  background: #fff;
  border-radius: 50%;
`;

const BrandSygnetBase = styled.div`
  width: 14px;
  height: 17px;
  background: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-top: 3px;
`;

const Small = styled.small`
  text-transform: none;
`;

class ShareLayout extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };

    this.getHeader = this.getHeader.bind(this);
    this.getFooter = this.getFooter.bind(this);
    this.logout = this.logout.bind(this);
  }

  getHeader() {
    const isSignedIn = AuthApi.checkSession();

    return (
      <header style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '30px'
      }}>
        <Link to={Routes.RootRoutePath}>
          <div className="logo" style={{
            display: 'flex',
            alignItems: 'center'
          }}>
            <BrandSygnet>
              <BrandSygnetDot />
              <BrandSygnetBase />
            </BrandSygnet>
            <LogoSeparator />
            <UserLogo style={{ backgroundImage: 'url("https://devapi.imglish.com/upload/1558651197sczaguxeb51714976467_1160862747333134_1307506156320767727_o.jpg")' }} />
          </div>
        </Link>
        <div className="action-bar">
          {
            isSignedIn ? (
              <button type="button" onClick={this.logout} className="clean">Logout</button>
            ) : (
              <Link to={Routes.SignInRoutePath}>Sign In</Link>
            )
          }
        </div>
      </header>
    );
  }

  getFooter() {
    return (
      <footer>Copyright by © CoyaPonk · 2018-2019 · <Small>v.{process.env.REACT_APP_VERSION}</Small></footer>
    );
  }

  logout() {
    AuthApi.logout().then((res) => {
      this.props.context.setContextState({
        isSignedIn: !res.success
      });
    });
  }

  render() {
    return (
      <AppContainer className="app-layout">
        {this.getHeader()}
        <GlobalStatus />
        {this.props.children}
        {this.getFooter()}
      </AppContainer>
    );
  }
}

ShareLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default withRouter((ShareLayout));
