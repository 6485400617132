import styled from 'styled-components';

export const TopSectionTitle = styled.h2`
  margin-top: 2.66rem;
  margin-bottom: 0.33rem;

  @media (max-width: 570px) {
    margin-top: 1.33rem;
    margin-bottom: 0rem;
  }
`;

export const AlbumCoverWrapper = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
`;

export const AlbumCover = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: 50%;
`;
