import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Droppable } from 'react-beautiful-dnd';

import Task from './task';

const TasksContainer = styled.div`
  display: block;
  width: 100%;
  min-height: 100%;
  background: #fff;
  border-radius: 7px;
  padding-top: 1px;
  display: block;
  border: solid 3px #fff;

  display: ${props => (props.direction === 'horizontal' ? 'flex' : 'block')};
  background: ${props => (props.isDraggingOver ? '#e8f7e9' : '#fff')};
`;
const ColumnContainer = styled.div`
  width: 150px;
  height: 100%;
  overflow: auto;

  // position: fixed;
  // height: calc(100vh - 158px);
`;
const Placeholder = styled.div`
  width: 150px;

  display: ${props => (props.fixed ? 'block' : 'none')};
`;


class InnerTask extends React.PureComponent {
  render() {
    return this.props.tasks.map((task, index) => <Task key={task.id} task={task} index={index} column={this.props.column} />);
  }
}

const Column = (props) => {
  const [fixed, setFixed] = useState(window.pageYOffset >= 0);

  // useEffect(() => {
  //   const onScroll = (e) => {
  //     if (window.pageYOffset >= 0) {
  //       setFixed(true);
  //     } else {
  //       setFixed(false);
  //     }
  //   };

  //   window.addEventListener('scroll', onScroll);

  //   return () => {
  //     window.removeEventListener('scroll', onScroll);
  //   };
  // });

  return (
    <div>
      <ColumnContainer fixed={fixed}>
        <Droppable droppableId={props.column.id} direction={props.column.direction} type="task">
          {(provided, snapshot) => (
            <TasksContainer
              innerRef={provided.innerRef}
              direction={props.column.direction}
              {...snapshot}
              {...provided.droppableProps}
            >
              <InnerTask tasks={props.tasks} column={props.column.id} />
              {provided.placeholder}
            </TasksContainer>
          )}
        </Droppable>
      </ColumnContainer>
      <Placeholder fixed={fixed} />
    </div>
  );
};

export default Column;
