export default () => {
  function scroll(a, b) {
    return window.addEventListener('scroll', () => {
      clearTimeout(b);
      b = setTimeout(a, 100);
    }), a;
  }

  function resize(a, b) {
    return window.addEventListener('resize', () => {
      clearTimeout(b);
      b = setTimeout(a, 100);
    }), a;
  }

  function lazyestload() {
    // all the images with class lazyestload

    const images = document.querySelectorAll('img.lazyestload');
    let i = images.length;

    // loop de loop

    while (i--) {
      const wH = window.innerHeight;
      const boundingRect = images[i].getBoundingClientRect();
      const offset = 100;
      const yPositionTop = boundingRect.top - wH;
      const yPositionBottom = boundingRect.bottom;

      // if the top of the image is within 100px from the bottom of the viewport
      // and if the bottom of the image is within 100px from the top of the viewport
      // basically if the image is in the viewport, with a bit of buffer

      if (yPositionTop <= offset && yPositionBottom >= -offset) {
        // replace the src with the data-src

        if (images[i].getAttribute('data-src')) {
          images[i].src = images[i].getAttribute('data-src');
        }

        // replace the srcset with the data-srcset

        if (images[i].getAttribute('data-srcset')) {
          images[i].srcset = images[i].getAttribute('data-srcset');
        }

        // replace the source srcset's with the data-srcset's

        if (images[i].parentElement.tagName === 'PICTURE') {
          const sources = images[i].parentElement.querySelectorAll('source');
          let j = sources.length;
          while (j--) {
            sources[j].srcset = sources[j].getAttribute('data-srcset');
          }
        }

        // wait until the new image is loaded

        images[i].addEventListener('load', function () {
          this.classList.remove('lazyestload');
        });
      }
    }
  }

  scroll(() => {
    lazyestload();
  })();

  resize(() => {
    lazyestload();
  })();
};
