import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import App from '../../core/app';
import Status from '../../shared/ui/status/status';

class globalStatus extends Component {
  constructor(props) {
    super(props);

    this.toastTimeout = null;

    this.state = {
      status: null,
      setContextState: (newState, callback) => {
        if (newState.status && newState.status.mode === 'toast') {
          this.toastTimeout = setTimeout(() => {
            this.setState({ status: null });
          }, newState.status.time || 3000);
        }

        this.setState(newState, (...pms) => {
          if (newState) {
            if (newState.status && !newState.status.noScroll) {
              window.scrollTo(0, 0);
            }
          }

          if (callback) {
            callback(...pms);
          }
        });
      }
    };

    App.set('globalStatus', this.state);
  }

  componentWillUnmount() {
    clearTimeout(this.toastTimeout);
  }

  componentWillMount() {
    if (!document.getElementById('globalStatus')) {
      const rootContainer = document.getElementById('root');
      const globalStatusPortalContainer = document.createElement('div');

      globalStatusPortalContainer.setAttribute('id', 'globalStatus');
      rootContainer.parentNode.insertBefore(globalStatusPortalContainer, rootContainer.nextSibling);

      this.globalStatusPortalContainer = globalStatusPortalContainer;
    } else {
      this.globalStatusPortalContainer = document.getElementById('globalStatus');
    }
  }

  render() {
    const { status } = this.state;

    return ReactDOM.createPortal(
      status ? <Status {...status} /> : null,
      this.globalStatusPortalContainer
    );
  }
}

export default globalStatus;
