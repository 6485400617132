// ####### Declarations ##########

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './authenticate.css';

import { withRouter, Redirect } from 'react-router-dom';
import Routes from '../../modules/routes';

import AuthApi from '../../core/api/auth';

// ########### Composing view ###########

class Auth extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentWillMount() {
    if (AuthApi.checkSession()) {
        AuthApi.check();        
      }
  }

  render() {
    const { children: component, location } = this.props;
    const isSignedIn = AuthApi.checkSession();

    // if user is signed in, return view
    if (isSignedIn) {
      // If user is signed in, but want to see login page or register page, redirect him to dashboard
      if (location.pathname === Routes.SignInRoutePath || location.pathname === Routes.SignUpRoutePath) {
        return <Redirect to={Routes.DashboardRoutePath} />;
      }

      return component;
    }

    // if user is not signed in, but URL points to login page, show those him
    if (location.pathname === Routes.SignInRoutePath) {
      return component;
    }

    // if user is not signed in, but URL points to root, show root
    if (location.pathname === Routes.RootRoutePath) {
      return component;
    }

    // if user is not signed in, but URL points to registration page, let him register
    if (location.pathname === Routes.SignUpRoutePath) {
      return component;
    }

    // if user is not signed in, but want to access any website resource, let him login first
    return <Redirect to={Routes.SignInRoutePath} />;
  }
}

Auth.defaultProps = {
  location: {
    pathname: ''
  }
};

Auth.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  })
};

export default withRouter((Auth));
