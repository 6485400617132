// ####### Declarations ##########

import React, { Component, Fragment } from 'react';

import App from 'core/app';

import styled from 'styled-components';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import { debounce, map, uniqBy, sumBy, take } from 'lodash';
import { withRouter } from 'react-router-dom';
import { Input } from 'shared/ui/ui';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toSize, getContrastColor } from '../../shared/utils/helpers';

import * as MS from '../../modules/modules.styled';

const LightboxCounter = styled.div`
  position: absolute;
  left: 1.33rem;
  top: 0;
  font-size: 0.875rem;
`;

const ImglishDND = styled.div``;

// ########### Composing view ###########

class SharedAlbum extends Component {
  constructor(props) {
    super(props);

    this.gridSize = Math.round((window.innerWidth * 20) / 1440);
    this.gridOffset = Math.floor(0.00238 * window.innerWidth + 0.283);
    this.grid = this.gridSize - Math.round(0.00357 * window.innerWidth + 0.857);

    this.photosBoxRef = React.createRef();

    this.state = {
      grid: this.grid,
      gridSize: this.gridSize,
      gridOffset: this.gridOffset,
      ready: false,
      lightboxOpen: false,
      lightboxIndex: 0,
      containerWidth: 0
    };

    this.resizeLayout = this.resizeLayout.bind(this);
    this.resizeFn = debounce(this.resizeLayout, 300);
    this.handleLightBoxClose = this.handleLightBoxClose.bind(this);
    this.openImage = this.openImage.bind(this);
    this.colorCopied = this.colorCopied.bind(this);
    this.updateGrid = this.updateGrid.bind(this);
  }

  resizeLayout() {
    this.setState((prevState) => {
      const nextContainerWidth = this.photosBoxRef.current.getBoundingClientRect().width;
      const nextGridOffset = Math.floor(0.00238 * window.innerWidth + 0.283);
      const nextGridSize = Math.round((window.innerWidth * 20) / 1440);
      let nextGrid = Math.round((prevState.grid / prevState.gridSize) * nextGridSize);

      if (nextGrid + nextGridOffset > nextGridSize) {
        nextGrid = nextGridSize - nextGridOffset;
      }

      if (nextGrid < 0) {
        nextGrid = 1;
      }

      return {
        gridOffset: nextGridOffset,
        gridSize: nextGridSize,
        grid: nextGrid,
        containerWidth: nextContainerWidth
      };
    });
  }

  handleLightBoxClose() {
    this.setState({
      lightboxOpen: false
    });
  }

  openImage(i) {
    this.setState({
      lightboxOpen: true,
      lightboxIndex: i
    });
  }

  colorCopied() {
    App.get('globalStatus').setContextState({
      status: {
        mode: 'toast',
        type: 'success',
        message: 'Color copied',
        noScroll: true
      }
    });
  }

  onDragEnd(result) {

  }

  updateGrid(step) {
    this.setState((prevState) => {
      let nextGrid = prevState.grid + step;

      if (nextGrid < 1) {
        nextGrid = 1;
      }

      if (nextGrid > prevState.gridSize - prevState.gridOffset) {
        nextGrid = prevState.gridSize - prevState.gridOffset;
      }

      return {
        grid: nextGrid
      };
    });
  }

  getGallery() {
    const { grid, gridSize, gridOffset, ready, containerWidth, lightboxOpen, lightboxIndex } = this.state;
    const { data, children } = this.props;
    const items = map(data.photos, el => el.file.path);
    const colors = uniqBy(data.photos, 'color');

    let viewContext = null;

    if (!ready) {
      viewContext = [];

      for (let i = 0; i < 20; i++) {
        viewContext.push(
          <MS.PhotoLoader key={i} grid={this.gridSize - grid} containerWidth={containerWidth} />
        );
      }
    } else if (children) {
      viewContext = children(this);
    } else {
      viewContext = map(data.photos, (photo, i) => {
        const photoWidth = containerWidth / (this.gridSize - grid);
        let sizeKey = 'min';

        if (photoWidth < photo.thumbnail.min.meta.width) {
          sizeKey = 'min';
        } else if (photoWidth < photo.thumbnail.mid.meta.width) {
          sizeKey = 'mid';
        } else {
          sizeKey = 'max';
        }

        return (
          <MS.Photo
            onClick={() => this.openImage(i)}
            key={i}
            grid={this.gridSize - grid}
            containerWidth={containerWidth}
            photo={photo}
            tabIndex="0"
            role="button"
            style={{ backgroundImage: 'url(' + photo.thumbnail[sizeKey].path + ')', backgroundColor: '#' + photo.color }}
          />
        );
      });
    }

    return (
      <div>
        <MS.ColorsBox>
          {/* <MS.ColorsLabel margin colors>{colors.length ? 'Album Pallete:' : <MS.LinePlaceholder style={{ width: '5rem' }} />}</MS.ColorsLabel> */}
          <MS.FlexBox>
            {
            //   <MS.ColorsWrapper>
            //     {colors.length ? map(take(colors, 5), (photo, k) => {
            //       const c = '#' + photo.color.toUpperCase();

            //       return (
            //         <CopyToClipboard
            //           key={k}
            //           text={c}
            //           onCopy={this.colorCopied}
            //         >
            //           <MS.ColorTile key={k} style={{ backgroundColor: c, color: getContrastColor(c) }}><span>{c}</span></MS.ColorTile>
            //         </CopyToClipboard>
            //       );
            //     }) : (
            //       <Fragment>
            //         <MS.ColorTilePlaceholder />
            //         <MS.ColorTilePlaceholder />
            //         <MS.ColorTilePlaceholder />
            //         <MS.ColorTilePlaceholder />
            //         <MS.ColorTilePlaceholder />
            //       </Fragment>
            //     )}
            //     {colors.length > 5 && <MS.ColorTile static style={{ backgroundColor: '#eee' }}>+{colors.length - 5}</MS.ColorTile>}
            //   </MS.ColorsWrapper>
            //   <MS.HorizonralSeparator colors />
            }
            <MS.ColorsLabel>{colors.length ? (
              <Fragment>
                <MS.PhotosIcon className="material-icons">photo</MS.PhotosIcon>
                {data.photos.length}
              </Fragment>
            ) : <MS.LinePlaceholder style={{ width: '3rem' }} />}
            </MS.ColorsLabel>
            <MS.HorizonralSeparator />
            <MS.ColorsLabel>{colors.length ? toSize(sumBy(data.photos, el => parseInt(el.file.size, 10))) : <MS.LinePlaceholder style={{ width: '3rem' }} />}</MS.ColorsLabel>
            <MS.HorizonralSeparator />
            <MS.SliderBox>
              {colors.length ? (
                <Fragment>
                  <MS.SmallImage role="button" onClick={() => this.updateGrid(-1)} />
                  <Input type="range" min="1" max={gridSize - gridOffset} value={grid} step="1" onChange={(e) => {
                    this.setState({
                      grid: e.target.value
                    });
                  }} styles={`
                    margin: 0;
                    padding: 0;
                  `} />
                  <MS.BigImage role="button" onClick={() => this.updateGrid(1)} />
                </Fragment>
              ) : <MS.LinePlaceholder style={{ width: '8rem' }} />}
            </MS.SliderBox>
            {
              this.props.options ? (
                colors.length ? (
                  map(this.props.options, (option, key) => (
                    <Fragment key={`op-${key}`}>
                      <MS.HorizonralSeparator />
                      {option.elm || null}
                    </Fragment>
                  ))
                ) : (
                  map(this.props.options, (option, key) => (
                    <Fragment key={`ph-${key}`}>
                      <MS.HorizonralSeparator />
                      <MS.LinePlaceholder style={option.placeholderStyle || { width: '3rem' }} />
                    </Fragment>
                  ))
                )
              ) : null
            }
          </MS.FlexBox>
        </MS.ColorsBox>
        {
          this.props.drag ? (
            <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable droppableId="imglish" direction="horizontal">
                {
                  provided => (
                    <MS.PhotosBox
                      {...provided.droppableProps}
                      containerWidth={containerWidth}
                      innerRef={provided.innerRef}
                    >
                      {viewContext}
                      {provided.placeholder}
                    </MS.PhotosBox>
                  )
                }
              </Droppable>
            </DragDropContext>
          ) : (
            <MS.PhotosBox containerWidth={containerWidth}>
              {viewContext}
            </MS.PhotosBox>
          )
        }
        {lightboxOpen && (
          <Lightbox
            toolbarButtons={[
              // function fullscreen() {
              //   if ((document.fullScreenElement && document.fullScreenElement !== null) ||
              //       (!document.mozFullScreen && !document.webkitIsFullScreen)) {
              //       if (document.documentElement.requestFullScreen) {
              //           document.documentElement.requestFullScreen();
              //       } else if (document.documentElement.mozRequestFullScreen) {
              //           document.documentElement.mozRequestFullScreen();
              //       } else if (document.documentElement.webkitRequestFullScreen) {
              //           document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
              //       }
              //   } else {
              //       if (document.cancelFullScreen) {
              //           document.cancelFullScreen();
              //       } else if (document.mozCancelFullScreen) {
              //           document.mozCancelFullScreen();
              //       } else if (document.webkitCancelFullScreen) {
              //           document.webkitCancelFullScreen();
              //       }
              //   }
              // }
              // (
              //   <button type="button" aria-label="Zoom in" className="ril__toolbarItemChild ril__builtinButton">
              //     <i className="material-icons">fullscreen</i>
              //   </button>
              // ),
              <LightboxCounter>{lightboxIndex + 1} / {items.length}</LightboxCounter>
            ]}
            mainSrc={items[lightboxIndex]}
            nextSrc={items[(lightboxIndex + 1) % items.length]}
            prevSrc={items[(lightboxIndex + items.length - 1) % items.length]}
            onCloseRequest={() => this.setState({ lightboxOpen: false })}
            onMovePrevRequest={() => this.setState({
              lightboxIndex: (lightboxIndex + items.length - 1) % items.length
            })}
            onMoveNextRequest={() => this.setState({
              lightboxIndex: (lightboxIndex + 1) % items.length
            })}
          />
        )}
      </div>
    );
  }

  componentDidMount() {
    this.setState({
      ready: true,
      containerWidth: this.photosBoxRef.current.getBoundingClientRect().width
    });

    window.addEventListener('resize', this.resizeFn);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeFn);
  }

  render() {
    return (
      <div ref={this.photosBoxRef}>
        {this.getGallery()}
      </div>
    );
  }
}

export default withRouter((SharedAlbum));
