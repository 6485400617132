import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';

import './auth.css';

import AuthApi from 'core/api/auth';

import AppLayout from 'components/layouts/default/default';

import { ModuleContainer } from '../modules.styled';
import Routes from '../routes';

import SignIn from './signIn/signIn';
import SignUp from './signUp/signUp';
import ForgotPassword from './forgotPassword/forgotPassword';


class Auth extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };

    this.viewContexts = {
      signIn: Routes.SignInRoutePath,
      signUp: Routes.SignUpRoutePath,
      forgotPassword: Routes.ForgotPath
    };

    this.getViewContext = this.getViewContext.bind(this);
  }

  getViewContext() {
    const { path } = this.props.match;

    switch (path) {
      case this.viewContexts.signIn:
        return <SignIn {...this.props} />;
      case this.viewContexts.signUp:
        return <SignUp {...this.props} />;
      case this.viewContexts.forgotPassword:
        return <ForgotPassword {...this.props} />;
      default:
        return <div />;
    }
  }

  render() {
    const isSignedIn = AuthApi.checkSession();

    if (isSignedIn) {
      return <Redirect to={Routes.DashboardRoutePath} />;
    }

    return (
      <AppLayout>
        <ModuleContainer styled={`
          background: #f3f3f3;
          border-radius: 1rem;
          padding: 1rem;
          box-sizing: border-box;
          display: flex;
          justify-content: center;
          text-align: center;
        `}>
          {this.getViewContext()}
        </ModuleContainer>
      </AppLayout>
    );
  }
}

export default withRouter(Auth);
