// ####### Declarations ##########
import styled from 'styled-components';
import { MenuItem } from '@trendmicro/react-dropdown';
import { FormGroup } from '../../shared/styled/styled';

// ####### Styled Components ##########

const ButtonContainer = styled.div`
  display: flex;

  ${FormGroup} {
    width: 100%;
  }
`;

const Box = styled.div`
  max-width: 540px;
  left: 0;
  right: 0;
  margin: auto;
`;

const TitlePlaceholder = styled.div`
  width: 95%;
  height: 2.66rem;
  background: linear-gradient(180deg, #ececec, #f7f7f7);
  background-size: 400% 400%;
  animation: placeholder 1s ease-in-out infinite;
  border-radius: 10rem;
  margin-bottom: 1rem;
`;

const BreadcrumbsPlaceholder = styled.div`
  width: 50%;
  height: 1.66rem;
  background: linear-gradient(180deg, #ececec, #f7f7f7);
  background-size: 400% 400%;
  animation: placeholder 1s ease-in-out infinite;
  border-radius: 10rem;
  margin-bottom: 1rem;
`;

const MenuItemPlaceholder = styled.div`
  width: 2.66rem;
  height: 2.66rem;
  background: linear-gradient(180deg, #ececec, #f7f7f7);
  background-size: 400% 400%;
  animation: placeholder 1s ease-in-out infinite;
  border-radius: 50%;
  margin-left: 1rem;
  display: inline-block;
`;

const CopyMenuItem = styled(MenuItem)`
  > [role="menuitem"] {
    // padding: 0 !important;

    > span {
      width: 100%;
      display: block;
      padding: 4px 16px;
    }
  }
`;

const LinkMenuItem = styled(MenuItem)`
  > [role="menuitem"] {
    padding: 0 !important;

    > a {
      width: 100%;
      display: block;
      padding: 4px 16px;
    }
  }
`;

const MenuItemFlexbox = styled.div`
  display: flex;
  align-items: center;

  i {
    font-size: 1.33em;
    margin-right: 0.66em;
  }
`;

const DangerZone = styled.p`
  text-align: center;
  border-bottom: solid 1px;
  color: #f21c40;
  padding-bottom: 0.66rem;
  margin-top: 3rem;
`;

const FloatingAddButtonBox = styled.div`
  position: fixed;
  z-index: 1;
  bottom: 0;
  right: 1rem;
`;

const AlbumMenuBox = styled.div`
  white-space: nowrap;
  margin-top: 6px;

  @media (max-width: 470px) {
    display: none;
  }
`;

const UploadPlaceholderBox = styled.div`
  margin-top: 5rem;

  @media (max-width: 470px) {
    margin-top: 1.33rem;
  }
`;

const UploadPlaceholderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 6rem;
  border: 2px dashed rgb(238, 238, 238);
  border-radius: 1rem;
  color: rgb(204, 204, 204);

  @media (max-width: 470px) {
    ${props => (props.isMobile ? `
      padding: 3rem 1rem;
    ` : `
      padding: 2.33rem 1rem 0.66rem;
    `)}
    flex-direction: column;
  }
`;

const AlbumTopbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const ReactSelectBox = styled.div`
  max-width: 14rem;
  width: 100%;
`;

// ####### Export ##########

export default {
  ButtonContainer,
  Box,
  TitlePlaceholder,
  BreadcrumbsPlaceholder,
  MenuItemPlaceholder,
  CopyMenuItem,
  LinkMenuItem,
  MenuItemFlexbox,
  DangerZone,
  FloatingAddButtonBox,
  AlbumMenuBox,
  UploadPlaceholderBox,
  UploadPlaceholderWrapper,
  AlbumTopbar,
  ReactSelectBox
};
