// ####### Declarations ##########
import React from 'react';
import styled from 'styled-components';

import { dConnect } from '../../../../shared/utils/helpers';
import { Form, Input, Textarea } from '../../../../shared/ui/ui';

// ########### Styled Components ############

const FormBox = styled.div`
  width: 42rem;
`;

// ########### Composing view ###########
const Contact = (props) => {
  const a = '';

  return (
    <div>
      <Form>
        {
          () => (
            <FormBox>
              <Input type="email" label="Enter your email" />
              <Input type="text" label="Enter your name" />
              <Textarea label="Your message" />
            </FormBox>
          )
        }
      </Form>
    </div>
  );
};

// ########### Export ###########
export default dConnect(Contact);
