// ####### Declarations ##########

import React, { Component } from 'react';

import ShareAppLayout from 'components/layouts/share/share';

import { debounce } from 'lodash';
import { withRouter } from 'react-router-dom';
import { Button } from 'shared/ui/ui';

import GridGallery from '../../../components/gridGallery/gridGallery';

import * as MS from '../../modules.styled';
import styled from 'styled-components';

// ########### Composing view ###########

const Hd = styled.h5`
margin-top: 3px;
margin-bottom: -10px;
color: rgb(123, 123, 123);
font-weight: 500;
`;

class SharedAlbum extends Component {
  constructor(props) {
    super(props);

    this.photosBoxRef = React.createRef();
    this.state = {
      ready: false,
      grid: 15,
      containerWidth: 0
    };

    this.resizeLayout = this.resizeLayout.bind(this);
  }

  resizeLayout() {
    this.setState({
      containerWidth: this.photosBoxRef.current.getBoundingClientRect().width
    });
  }

  getViewContext() {
    return (
      <div className="animated fadeIn">
        <MS.HeaderBox>
          <MS.Bar>
            <MS.Title>{this.props.data.album.name}</MS.Title>
            {
            //   <MS.ButtonsBar>
            //   <Button rtl>Download</Button>
            // </MS.ButtonsBar>
          }
          </MS.Bar>
          <Hd>{this.props.data.album.description}</Hd>
          {
          // <MS.UserInfoBox>
          //   <MS.Avatar style={{ backgroundImage: 'url("https://devapi.imglish.com/upload/1558651197sczaguxeb51714976467_1160862747333134_1307506156320767727_o.jpg")' }} />
          //   <MS.UserName>Szymon Pajka</MS.UserName>
          // </MS.UserInfoBox>
          }
        </MS.HeaderBox>
        <GridGallery {...this.props} />
      </div>
    );
  }

  componentDidMount() {
    this.setState({
      ready: true,
      containerWidth: this.photosBoxRef.current.getBoundingClientRect().width
    });

    window.addEventListener('resize', debounce(this.resizeLayout, 100));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeLayout);
  }

  render() {
    return (
      <ShareAppLayout>
        <div ref={this.photosBoxRef}>
          {this.getViewContext()}
        </div>
      </ShareAppLayout>
    );
  }
}

export default withRouter((SharedAlbum));
