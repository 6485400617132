import React from 'react';
import styled from 'styled-components';
import { Draggable } from 'react-beautiful-dnd';

const TaskTile = styled.div`
  transition: background ease-in 0.1s;
  display: block;
  margin: 5px;
  background: transparent;

  ${props => props.width && `
    width: ${props.width}px;
  `}

  // width: auto;
  // height: auto;
  // box-sizing: border-box;
  
`;
const Photo = styled.div`
  width: 100%;
  padding-bottom: ${props => props.ratio}%;
  background-image: url(${props => props.url});
  background-size: cover;
  box-sizing: border-box;

  ${props => (props.isDragging ? `
    border: solid 1px red;
  ` : `
    border: solid 1px #c1c1c1;
  `)};
`;

class Task extends React.PureComponent {
  render() {
    const ratio = (this.props.task.thumbnail.max.meta.height / this.props.task.thumbnail.max.meta.width * 100).toFixed(4);

    return (
      <Draggable draggableId={this.props.task.id} index={this.props.index}>
        {(provided, snapshot) => (
          <TaskTile
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            innerRef={provided.innerRef}
            width={this.props.isDrag ? null : this.props.width}
          >
            <Photo
              isDragging={snapshot.isDragging}
              url={this.props.task.thumbnail.max.path}
              column={this.props.column}
              ratio={ratio}
            />
          </TaskTile>
        )}
      </Draggable>
    );
  }
}

export default Task;
