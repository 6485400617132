import React from 'react';
import { Link } from 'react-router-dom';

import './signUp.css';

import merge from 'lodash/merge';
import { Input, Form } from 'shared/ui/ui';
import { BrandSygnet, BrandSygnetBase, BrandSygnetDot } from 'components/components.styled';
import styled from 'styled-components';
import APIsignUp from '../../../core/api/APIsignUp';
import AuthApi from '../../../core/api/auth';
import UserApi from '../../../core/api/APIsettings';
import { CreateReduxComponent } from '../../../self/component';
import { setUserData } from '../../../actions/user';
import { dConnect } from '../../../shared/utils/helpers';

import App from '../../../core/app';

const NameBox = styled.div`
  display: flex;
  width: calc(100% + 1rem);
`;

class SignUp extends CreateReduxComponent {
  onLoginResponse = async (res) => {
    if (res.success) {
      const userRes = await UserApi.get();
      const userData = userRes.message;

      this.dispatch(setUserData(userData));
    }
  }

  render() {
    return (
      <Form
        api={APIsignUp.signUp}
        onSuccess={(_, state) => {
          AuthApi.login({
            email: state.email.value,
            password: state.password.value
          }).then(res => this.onLoginResponse(res));
        }}
        onError={() => {
          App.get('globalStatus').setContextState({
            status: {
              mode: 'toast',
              type: 'error',
              message: 'Some server problem (will be handled)',
              time: 5000,
              noScroll: true
            }
          });
        }}
        onBeforeSubmit={(state, actions) => {
          if (state.password.value === state.confirmPassword.value) {
            return true;
          }

          actions.setState(prevState => ({
            password: merge(prevState.password, { valid: false }),
            confirmPassword: merge(prevState.confirmPassword, { valid: false })
          }), () => {
            App.get('globalStatus').setContextState({
              status: {
                mode: 'toast',
                type: 'warning',
                message: "Oops.. The passwords you've just input are not matching. Please correct them, as you'll need it to log in.",
                time: 5000,
                noScroll: true
              }
            });
          });

          return false;
        }}
        defaultState={{
          email: '',
          first_name: '',
          last_name: '',
          password: '',
          confirmPassword: ''
        }}
      >
        {actions => (
          <div className="formDiv">
            <BrandSygnet styled={`
              transform: scale(1.5);
              left: 0;
              right: 0;
              margin: auto;
              margin-bottom: -2rem;
              margin-top: 4rem;
            `}>
              <BrandSygnetDot styled={`
                background: #f3f3f3;
              `} />
              <BrandSygnetBase styled={`
                background: #f3f3f3;
              `} />
            </BrandSygnet>
            <h3>Register your account</h3>
            <p style={{ fontSize: '1.16rem', marginTop: '-0.33rem', color: '#565656' }}>You're one step to go! Fill of the fields below and start using talking using images with imglish!</p>
            <br />
            <Input name="email" type="email" placeholder="Email" value={actions.state.email.value} valid={actions.state.email.valid} onChange={event => actions.handleChange(event)} />
            <NameBox>
              <Input name="first_name" type="text" placeholder="First name" value={actions.state.first_name.value} valid={actions.state.first_name.valid} onChange={event => actions.handleChange(event)} />
              <Input name="last_name" type="text" placeholder="Last name" value={actions.state.last_name.value} valid={actions.state.last_name.valid} onChange={event => actions.handleChange(event)} />
            </NameBox>
            <Input name="password" type="password" placeholder="Password" value={actions.state.password.value} valid={actions.state.password.valid} onChange={event => actions.handleChange(event)} />
            <Input name="confirmPassword" type="password" placeholder="Confirm password" value={actions.state.confirmPassword.value} valid={actions.state.confirmPassword.valid} onChange={event => actions.handleChange(event)} />
            <Input type="submit" value="Register" className="button" />
            <Link className="link" to="/signin">Already have an account? Sign in →
            </Link>
            <br />
          </div>
        )}
      </Form>
    );
  }
}

export default dConnect(SignUp);
