import React from 'react';
import { Form, Input, Textarea, Button } from 'shared/ui/ui';


import AppLayout from 'components/layouts/default/default';
import Authenticate from 'components/authenticate/authenticate';
import Api from '../../api';
import SC from '../../album.styled';
import ReactTooltip from 'react-tooltip';

class getSettings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      album: {}
    };
  }

  componentDidMount() {
    this.loadAlbum();
  }

  async loadAlbum() {
    const { id } = this.props.match.params;
    const res = await Api.getAlbum({ id });

    this.setState({ album: res.message.album });
  }

  deleteAlbum = (e) => {
    e.preventDefault();

    const { id } = this.state.album;

    Api.deleteAlbum({ id }).then((res) => {
      if (res.success) {
        const { history } = this.props;
        history.push('/dashboard');
      }
    });
  }

  handleAlbumURIChange(actions, event) {
    const { uri } = this.state.album.url;
    const user = uri.substr(0, uri.indexOf('/'));

    const albumLinkModifier = (value) => {
      if (value.indexOf(`${user}/`) === 0) {
        return value;
      }

      return `${user}/`;
    };

    return actions.handleChange(event, albumLinkModifier);
  }


  renderSettings() {
    const { id, description, name } = this.state.album;
    const { params } = this.props.match;
    const { history, match } = this.props;
    if (id === params.id) {
      const { uri } = this.state.album.url;
      const user = uri.substr(0, uri.indexOf('/'));

      return (
        <>
          <div style={{ marginBottom: '5rem' }} />
          <section>
            <Form
              id="settings-form"
              api={Api.updateAlbum}
              onBeforeSubmit={(actions) => {
                actions.uri.value = actions.uri.value.split(`${user}/`).pop();

                return true;
              }}
              onSuccess={(res) => {
                if (res.success) {
                  history.push(`/album/${match.params.id}`);
                }
              }}
              onError={(res) => { console.log(res); }}
              allowEmpty={['description']}
              defaultState={{
                id,
                description,
                name,
                uri
              }}
            >
              {
                actions => (
                  <SC.Box>
                    {
                      // <div style={{
                      //   display: 'flex',
                      //   flexDirection: 'column',
                      //   alignItems: 'center'
                      // }}>
                      //   {photo && <Input type="hidden" name="name" value={actions.state.photo_id} />}
                      //   {photo && <Photo type="saved-image" key={'si-' + photo.id} id={photo.id} index={0} preview={photo.thumbnail} file={photo} album={album} style={{ width: '186px' }} />}
                      // </div>
                    }
                    <Input label="Album name" name="name" value={actions.state.name.value || ''} onChange={event => actions.handleChange(event)} />
                    <Input label="Album Link" name="uri" value={actions.state.uri.value || ''} onChange={event => this.handleAlbumURIChange(actions, event)} />
                    <Textarea label="Album description" name="description" value={actions.state.description.value || ''} placeholder="Description will be shown on your website or when you'll share this album" onChange={event => actions.handleChange(event)} />
                    <SC.ButtonContainer>
                      <Input primary info type="submit" value="Save Changes" />
                    </SC.ButtonContainer>
                    <div />
                    <SC.DangerZone>Danger zone</SC.DangerZone>
                    <SC.ButtonContainer>
                      <Button danger onClick={this.deleteAlbum} style={{ marginRight: 0 }}>Remove Album</Button>
                    </SC.ButtonContainer>
                  </SC.Box>
                )
              }
            </Form>
          </section>
        </>
      );
    }

    return null;
  }

  render() {
    const { history, match } = this.props;
    return (
      <AppLayout>
        <Authenticate>
          <div key="settings" className="animated fadeIn">
            <SC.AlbumTopbar>
              <section>
                <h3 style={{ marginTop: '0', marginBottom: '0.5rem' }}>Manage your album</h3>
                <h5 className="light mt0" style={{ color: '#7b7b7b' }}>Change album settings</h5>
              </section>
              <div style={{ whiteSpace: 'nowrap', marginTop: '6px' }}>
                <button tabIndex="0" className="button option-button" type="submit" form="settings-form" data-tip="Save and Back to Album">
                  <i className="material-icons">save</i>
                </button>
                <button tabIndex="0" className="button option-button" type="button" data-tip="Back to Album" onClick={() => history.push(`/album/${match.params.id}`)}>
                  <i className="material-icons">arrow_back</i>
                </button>
                <ReactTooltip place="top" type="dark" effect="solid" />
              </div>
            </SC.AlbumTopbar>
            {this.renderSettings()}
          </div>
        </Authenticate>
      </AppLayout>

    );
  }
}

export default getSettings;
