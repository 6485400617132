// ####### Declarations ##########
import React from 'react';
import styled from 'styled-components';

import { dConnect } from '../../../../shared/utils/helpers';

// ########### Public Styled Components ############
export const AboutContainer = styled.div`
  display: flex;
`;
export const AboutCopy = styled.div`
  max-width: 42rem;

  > p {
    font-size: 1.1rem;
    line-height: 1.55;
  }
`;
export const Skills = styled.div`
  margin-left: 5rem;

  > ul {
    margin: 0;
    list-style: none;
    padding: 0;
    font-size: 1.1rem;
    line-height: 1.55;
  }
`;

// ########### Composing view ###########
const About = (props) => {
  const a = '';

  return (
    <AboutContainer className="animated fadeIn">
      <AboutCopy>
        <h4>About {props.app.user.details.first_name}</h4>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Venenatis lectus magna fringilla urna porttitor rhoncus. Tellus pellentesque eu tincidunt tortor aliquam nulla facilisi cras fermentum. In nisl nisi scelerisque eu ultrices vitae auctor eu. Diam vulputate ut pharetra sit amet aliquam id diam maecenas. Eu augue ut lectus arcu bibendum at varius vel. Molestie at elementum eu facilisis sed odio morbi. Pretium quam vulputate dignissim suspendisse in est ante in nibh. Eget dolor morbi non arcu risus. Gravida in fermentum et sollicitudin ac. Interdum velit laoreet id donec ultrices tincidunt. Facilisi etiam dignissim diam quis enim lobortis.</p>
        <p>Egestas diam in arcu cursus euismod quis viverra. Cras ornare arcu dui vivamus arcu felis bibendum ut. Non sodales neque sodales ut etiam. Turpis nunc eget lorem dolor sed viverra ipsum nunc aliquet. Massa eget egestas purus viverra accumsan in nisl. Nunc sed id semper risus in hendrerit gravida rutrum quisque. Integer vitae justo eget magna fermentum iaculis eu non diam. Donec ac odio tempor orci dapibus ultrices in iaculis. Odio tempor orci dapibus ultrices in iaculis nunc. Etiam erat velit scelerisque in dictum. Sagittis nisl rhoncus mattis rhoncus urna neque viverra justo. Sed vulputate mi sit amet mauris commodo. Tristique sollicitudin nibh sit amet commodo nulla. Aliquet nec ullamcorper sit amet risus nullam eget. Cursus in hac habitasse platea. Amet dictum sit amet justo donec enim diam vulputate. Est lorem ipsum dolor sit amet consectetur. Nulla posuere sollicitudin aliquam ultrices sagittis orci a scelerisque purus. Adipiscing vitae proin sagittis nisl rhoncus. Turpis cursus in hac habitasse platea dictumst quisque sagittis.</p>
      </AboutCopy>
      <Skills>
        <h4>{props.app.user.details.first_name}'s skills</h4>
        <ul>
          <li>Photoshop</li>
          <li>HTML/CSS</li>
          <li>React</li>
          <li>Photoshop</li>
          <li>HTML/CSS</li>
          <li>React</li>
          <li>Photoshop</li>
          <li>HTML/CSS</li>
          <li>React</li>
        </ul>
      </Skills>
    </AboutContainer>
  );
};

// ########### Export ###########
export default dConnect(About);
