// ######### Imports ####################
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import AuthApi from '../../core/api/auth';
import { ROUTE_PATH } from './route-path';

import Dashboard from './dashboard';

// ######### Composing Routes ####################
const Routes = () => {
  const ready = AuthApi.checkSession();

  if (!ready) {
    return <Redirect to="/signin" />;
  }

  return (
    <Switch>
      <Route exact path="/" component={Dashboard} />
      <Route exact path={ROUTE_PATH} component={Dashboard} />
    </Switch>
  );
};

// ######### Exports ####################
export default Routes;
