import React from 'react';
import styled from 'styled-components';

export const ModuleContainer = (params) => {
  const { children, ...pms } = params;
  const ModuleStyledContainer = styled.div`
    ${props => props.center && `
      text-align: center;
    `}

    ${props => props.styled}
  `;

  return <ModuleStyledContainer className="animated fadeIn" {...pms}>{children}</ModuleStyledContainer>;
};

export const Title = styled.h3`
  margin: 0;
`;

export const ButtonsBar = styled.div`
  font-size: 1rem;
  display: flex;
`;

export const UserInfoBox = styled.div`
  display: flex;
  align-items: center;
`;

export const Bar = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between; 
`;

export const Avatar = styled.div`
  width: 2.66rem;
  height: 2.66rem;
  background: #cccccc;
  border-radius: 0.66rem;
  margin-right: 1rem;
  background-image: url(https://scontent-lht6-1.xx.fbcdn.net/v/t1.0-1/p320x320/29512854_894242607404240_4061163891507825721_n.jpg?_nc_cat=0&oh=50956bbaee93c52b2eef8a1953d6cd60&oe=5BCE99E3);
  background-size: contain;
`;

export const UserName = styled.a`
`;

export const HeaderBox = styled.div`
  position: relative;
`;

export const PhotosBox = styled.div`
  margin-top: 4rem;
  display: flex;
  flex-wrap: wrap;
  width: ${props => props.containerWidth + 1}px;

  @media (max-width: 470px) {
    margin-top: 3.33rem;
  }
`;

/*
 min

 0.66rem gutter
 0.33rem radius

 max

 1rem
 1rem
*/

export const Photo = styled.div`
  width: ${props => ((props.grid - 1) ? `
    calc((${(props.containerWidth / props.grid).toFixed(2)}px + (1rem / ${props.grid})) - 1rem)
  ` : `
    ${(props.containerWidth / props.grid).toFixed(2)}px
  `)};
  height: ${props => ((props.grid - 1) ? `
    calc((${((props.containerWidth / props.grid * 4) / 3).toFixed(2)}px + (1rem / ${props.grid})) - 1rem);
  ` : `
    ${((props.containerWidth / props.grid * 4) / 3).toFixed(2)}px
  `)};
  display: inline-block;

  ${props => props.noMargin !== true && `
    margin-right: 1rem;
    margin-bottom: 1rem;
  `}

  background-color: #eee;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 0px 0px 0px 1px #e0e0e0;

  ${props => props.photo && `
    background-size: cover;
    background-position: 50%;
  `}

  :nth-child(${props => props.grid}n + ${props => props.grid}){
    margin-right: 0;
  }
`;

export const PhotoLoader = styled(Photo)`
  background: linear-gradient(180deg, #ececec, #f7f7f7);
  background-size: 400% 400%;
  animation: placeholder 1s ease-in-out infinite;
  box-shadow: none;
  cursor: default;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SliderBox = styled.div`
  width: 140px;
  display: flex;
  align-items: center;
`;

export const SmallImage = styled.div`
  width: 11px;
  height: 11px;
  background: #b9b9b9;
  border-radius: 2px;
  margin-right: 7px;
`;

export const BigImage = styled.div`
  width: 17px;
  height: 17px;
  background: #b9b9b9;
  border-radius: 2px;
  margin-left: 7px;
`;

export const ColorsBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  margin-bottom: -2rem;
  border-bottom: solid 1px #eee;
  padding-bottom: 2rem;

  @media (max-width: 470px) {
    margin-top: 1rem;
    padding-bottom: 1rem;
  }
`;

export const ColorsWrapper = styled.div`
  display: flex;

  @media (max-width: 470px) {
    display: none;
  }
`;

export const ColorsLabel = styled.div`
  color: #3a3a3a;
  font-size: 0.875rem;
  display: flex;
  justify-content: center;
  white-space: nowrap;
  align-items: center;

  ${props => props.margin && `
    margin-bottom: 0.5rem;
  `}

  ${props => props.colors && `
    @media (max-width: 470px) {
      display: none;
    }
  `}
`;

export const ColorTile = styled.div`
  width: 2rem;
  height: 2rem;
  border-radius: 4px;
  margin-right: 0.33rem;
  font-size: 12px;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #a0a0a0;
  transition: all cubic-bezier(0.24, 0.64, 0.34, 1) 0.2s;
  cursor: pointer;

  > span {
    transition: all cubic-bezier(0.24, 0.64, 0.34, 1) 0.2s;
    pointer-events: none;
    opacity: 0;
  }

  ${props => props.static !== true && `
    :hover {
      width: 4.66rem;

      > span {
        display: block;
        pointer-events: all;
        opacity: 1;
      }
    }
  `}
`;

export const FlexBox = styled.div`
  display: flex;
  align-items: center;
`;

export const HorizonralSeparator = styled.div`
  width: 1px;
  height: 38px;
  display: inline-block;
  background: #cccccc;
  border-radius: 0.33rem;
  margin-right: 1.33rem;
  margin-left: 1.33rem;

  ${props => props.colors && `
    @media (max-width: 470px) {
      display: none;
    }
  `}
`;

export const PhotosIcon = styled.i`
  font-size: 1.33em;
  color: #565656;
  position: relative;
  top: 0px;

  ${props => (props.dir === 'left' ? `
    margin-left: 2px;
  ` : `
    margin-right: 2px;
  `)}
`;

export const PhotoWrapper = styled.div`
  display: flex;
  position: relative;
  margin-right: 1rem;
  margin-bottom: 1rem;

  :nth-child(${props => props.grid}n + ${props => props.grid}){
    margin-right: 0;

    [role="menu"] {
      left: -114px;
    }
  }

  :hover {
    > div {
      display: block;
    }
  }
`;

export const MenuWrapper = styled.div`
  display: none;
  position: absolute;
  right: 0;
  margin: 0.5rem;
`;

export const PhotoMenuIcon = styled.div`
  display: block;
  height: 0;
  position: relative;
  padding: 10px 4px;
  top: -12px;
  color: #7c7c7c;
`;

export const LinePlaceholder = styled.div`
  height: 0.875rem;
  background: linear-gradient(180deg, #ececec, #f7f7f7);
  background-size: 400% 400%;
  animation: placeholder 1s ease-in-out infinite;
  border-radius: 10rem;
`;

export const ColorTilePlaceholder = styled.div`
  width: 2rem;
  height: 2rem;
  border-radius: 4px;
  margin-right: 0.33rem;
  font-size: 12px;
  justify-content: center;
  align-items: center;
  display: flex;

  background: linear-gradient(180deg, #ececec, #f7f7f7);
  background-size: 400% 400%;
  animation: placeholder 1s ease-in-out infinite;
`;
