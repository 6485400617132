// STORAGE
export const SET_USER_ALBUMS = 'SET_USER_ALBUMS';
export const SET_ALBUM_DETAILS = 'SET_ALBUM_DETAILS';

// USER
export const SET_USER_DATA = 'SET_USER_DATA';

// GENERAL
export const INIT_APP_STATE = 'INIT_APP_STATE';
export const RESET_APP_STATE = 'RESET_APP_STATE';
