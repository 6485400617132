// ####### Declarations ##########

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';


// ########### Composing view ###########

class SharedAlbum extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="animated fadeIn">
        Hello Profile
      </div>
    );
  }
}

export default withRouter((SharedAlbum));
