// ####### Declarations ##########
import React, { Fragment } from 'react';
import { DropZoneContext } from 'core/context';
import Dropdown, { MenuItem, DropdownMenu } from '@trendmicro/react-dropdown';

import Routes from '../routes';
import { Link } from 'react-router-dom';

import Api from './api';
import App from '../../core/app';
import AppLayout from 'components/layouts/default/default';
import Authenticate from 'components/authenticate/authenticate';

import { Status } from '../../shared/ui/ui';
import { dConnect } from '../../shared/utils/helpers';
import { isMobile } from 'react-device-detect';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { map, concat, isObject, filter, find, forEach, has, orderBy } from 'lodash';

import ReactTooltip from 'react-tooltip';
import ReactSelect from 'react-select';

import Photo from './partials/photo';
import Name from './partials/name';
import Settings from './views/settings/settings';
import GridGallery from '../../components/gridGallery/gridGallery';

import * as MS from '../modules.styled';
import SC from './album.styled';
import { CreateReduxComponent } from '../../self/component';

import UploadService from '../../services/UploadService/UploadService';
import { setAlbumDetails } from '../../actions/storage';


// ########### Composing view ###########
class Album extends CreateReduxComponent {
  constructor(props) {
    super(props);

    const { id } = props.match.params;

    this.state = {
      id: '',
      title: '',
      context: '',
      filters: {
        order: 2
      },
      ready: false, //  !!this.props.app.storage.albumsDetails[id],
      share: false,
      pause: false,
      lightboxOpen: false,
      index: 0,
      uploaded: 0,
      photos: [],
      uploadedPhotos: [],
      album: [],
      imagesPerUpload: 1
    };

    this.CONSTS = {
      ACTION: {
        READY: 'READY'
      }
    };

    this.viewContexts = {
      gallery: 'gallery',
      settings: 'settings'
    };

    this.dropZoneContext = { state: { files: [] } };
    this.pendingUpload = [];

    // images allowed to be uploaded
    this.uploadBuffer = 0;

    this.getMenu = this.getMenu.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.loadAlbum = this.loadAlbum.bind(this);
    this.setStatus = this.setStatus.bind(this);
    this.exitAlbum = this.exitAlbum.bind(this);
    this.saveAlbum = this.saveAlbum.bind(this);
    this.getPhotos = this.getPhotos.bind(this);
    this.getGallery = this.getGallery.bind(this);
    this.createAlbum = this.createAlbum.bind(this);
    this.setUploaded = this.setUploaded.bind(this);
    this.getSettings = this.getSettings.bind(this);
    this.setPreUploaded = this.setPreUploaded.bind(this);
    this.getAlbumContext = this.getAlbumContext.bind(this);
    this.getOrderedPhotos = this.getOrderedPhotos.bind(this);
    this.getStatusContext = this.getStatusContext.bind(this);
    this.getGalleryOptions = this.getGalleryOptions.bind(this);
    this.getUploadPlaceholder = this.getUploadPlaceholder.bind(this);
    this.updateAlbumAfterDelete = this.updateAlbumAfterDelete.bind(this);

    this.fuse = new UploadService();
    this.fuse.registerSubscriberPopulator('Album', this.fetchData);
  }

  setStatus(status) {
    App.get('globalStatus').setContextState({
      status
    });
  }

  updateAlbumAfterDelete(id, callback) {
    // this.setState((prevState) => {
    //   const p = prevState.photos.slice();

    //   return {
    //     photos: filter(p, i => i.id !== id)
    //   };
    // }, callback);
    this.fetchData();
    callback();
  }

  setPreUploaded() {
    // if (this.uploadBuffer > 0) {
    //   this.uploadBuffer -= 1;
    //   this.forceUpdate();
    // }
  }

  shareLinkCopied() {
    App.get('globalStatus').setContextState({
      status: {
        mode: 'toast',
        type: 'success',
        message: 'Share link copied',
        noScroll: true
      }
    });
  }

  closeMenuItem() {
    document.body.click();
  }

  setUploaded(pms) {
    const { photo, file } = pms;

    this.pendingUpload = filter(this.pendingUpload, f => !f === file.fid);
    this.uploadBuffer -= 1;

    this.setState((prevState) => {
      const p = prevState.photos.slice();
      const up = prevState.uploadedPhotos.slice();

      p.push(photo);
      up.push(file);

      return {
        photos: p,
        uploadedPhotos: up,
        uploaded: prevState.uploaded + 1
      };
    });
  }

  exitAlbum() {
    this.props.history.push(Routes.DashboardRoutePath);
  }

  saveAlbum() {
    this.updateAlbum().then(() => {
      this.exitAlbum();
    });
  }

  getStatusContext() {
    const { files } = this.dropZoneContext;
    const { uploadedPhotos, photos, pause } = this.state;

    // if (files.length !== uploadedPhotos.length) {
    //   return {
    //     type: 'info',
    //     message: (
    //       <div>
    //         Uploaded {uploadedPhotos.length} of {files.length} photos.
    //         <button type="button" onClick={() => this.setState(prevState => ({
    //           pause: !prevState.pause
    //         }))}>{pause ? 'Continue' : 'Pause'}
    //         </button>
    //       </div>
    //     )
    //   };
    // }

    // if (files.length > 0 && this.dropZoneContext.files.length === uploadedPhotos.length) {
    //   return {
    //     type: 'success',
    //     message: 'Your photos has been uploaded 😍'
    //   };
    // }

    // if (!photos.length) {
    //   if (isMobile) {
    //     return {
    //       type: 'info',
    //       message: 'This album is empty. To add photos click "Upload Photos" and select files to upload. All Changes are saved automatically.'
    //     };
    //   }

    //   return {
    //     type: 'info',
    //     message: 'This album is empty. To add photos drop them anywhere or click "Upload Photos" and select files to upload. All Changes are saved automatically.'
    //   };
    // }

    return {
      style: {
        display: 'none'
      }
    };
  }

  getUploadPlaceholder() {
    if (isMobile) {
      return (
        <div>
          <SC.UploadPlaceholderWrapper isMobile>
            <button type="button" className="button" style={{ margin: 0 }} onClick={() => this.dropZoneContext.dropzoneRef.open()}>Upload Photos</button>
          </SC.UploadPlaceholderWrapper>
        </div>
      );
    }

    return (
      <div>
        <SC.UploadPlaceholderWrapper>
          <button type="button" className="button" style={{ margin: 0 }} onClick={() => this.dropZoneContext.dropzoneRef.open()}>Upload Photos</button>
          <p>or</p>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
            <i className="material-icons" style={{ fontSize: '6rem', color: '#ddd' }}>
              add_photo_alternate
            </i>
            <p>Drop your photos anywhere</p>
          </div>
        </SC.UploadPlaceholderWrapper>
      </div>
    );
  }

  onFilterChange() {
    const { id } = this.props.match.params;
    const { album, photos } = this.props.app.storage.albumsDetails[id];
    const photosOrdered = this.getOrderedPhotos(photos, 'created');

    const data = {
      album,
      photos: photosOrdered
    };

    this.dispatch(setAlbumDetails(data));
  }

  getGalleryOptions() {
    const { filters } = this.state;
    const options = [
      // { value: 1, label: 'Show Your Order' }
      { value: 2, label: 'Show Oldest First' },
      { value: 3, label: 'Show Newest First' }
      // { value: 4, label: 'Show Best Rated First' },
      // { value: 5, label: 'Show Least Rated First' }
    ];

    return [
      {
        placeholderStyle: { maxWidth: '14rem', width: '100%' },
        elm: (
          <SC.ReactSelectBox>
            <ReactSelect
              className="ReactSelect"
              options={options}
              placeholder="Sort Photos"
              isSearchable={false}
              defaultValue={find(options, { value: filters.order })}
              onChange={(state, select) => {
                if (select.action === 'select-option') {
                  this.setState((prevState) => {
                    const f = prevState.filters;
                    f.order = state.value;

                    return {
                      filters: f
                    };
                  }, this.onFilterChange);
                }
              }}
            />
          </SC.ReactSelectBox>
        )
      }
    ];
  }

  getOrderedPhotos(photos, key) {
    const { filters } = this.state;
    switch (filters.order) {
      case 2:
        if (isObject(key)) {
          return concat(key, photos);
        }

        return orderBy(photos, key, 'asc');
      case 3:
        if (isObject(key)) {
          return concat(photos, key);
        }

        return orderBy(photos, key, 'desc');
      default:
        if (isObject(key)) {
          return concat(key, photos);
        }

        return photos;
    }
  }

  getPhotos() {
    const { id } = this.props.match.params;
    const { photos, album } = this.props.app.storage.albumsDetails[id] || {};
    const { imagesPerUpload, uploadedPhotos, ready, pause } = this.state;
    const { files } = this.dropZoneContext;

    const filteredFiles = [];

    forEach(files, (f) => {
      let passed = true;

      if (this.pendingUpload.indexOf(f.fid) !== -1) {
        passed = false;
      }

      if (find(uploadedPhotos, { fid: f.fid })) {
        passed = false;
      }

      if (passed) {
        filteredFiles.push(f);
      }
    });

    const photoTiles = this.getOrderedPhotos(
      this.getOrderedPhotos(
        map(filteredFiles, (photo, pid) => ({
          pid,
          photo,
          type: 'new-image'
        })),
        'pid'
      ),
      map(photos, (photo, pid) => ({
        pid,
        photo,
        type: 'saved-image'
      })),
    );

    if (photoTiles.length || !ready) {
      return (
        <GridGallery drag data={{ photos }} options={this.getGalleryOptions()}>
          {
            (gridGallery) => {
              const { grid, gridSize, containerWidth } = gridGallery.state;

              if (!ready) {
                const viewContext = [];

                for (let i = 0; i < 20; i++) {
                  viewContext.push(
                    <MS.PhotoLoader key={i} grid={gridSize - grid} containerWidth={containerWidth} />
                  );
                }

                return viewContext;
              }

              return map(photoTiles, (tile, index) => {
                if (tile.type === 'new-image') {
                  let upload = false;

                  if (!pause && !tile.photo.uploaded && this.uploadBuffer < imagesPerUpload) {
                    upload = true;
                    this.uploadBuffer += 1;

                    this.pendingUpload.push(tile.photo.fid);
                  }

                  return (
                    <Photo
                      id={index}
                      pid={tile.pid}
                      file={tile.photo}
                      album={album}
                      index={index}
                      upload={upload}
                      grid={gridSize - grid}
                      type="new-image"
                      key={'ni-' + index}
                      containerWidth={containerWidth}
                      setStatus={this.setStatus}
                      setUploaded={this.setUploaded}
                      setCanceled={this.dropZoneContext.updateFilesState}
                      setPreUploaded={this.setPreUploaded}
                      updateAlbum={this.updateAlbumAfterDelete}
                      onClick={() => gridGallery.openImage(index)}
                    />
                  );
                }

                return (
                  <Photo
                    id={tile.photo.id}
                    pid={tile.pid}
                    file={tile.photo}
                    album={album}
                    index={index}
                    grid={gridSize - grid}
                    type="saved-image"
                    key={'si-' + tile.photo.id}
                    containerWidth={containerWidth}
                    setStatus={this.setStatus}
                    setUploaded={this.setUploaded}
                    setPreUploaded={this.setPreUploaded}
                    updateAlbum={this.updateAlbumAfterDelete}
                    onClick={() => gridGallery.openImage(index)}
                  />
                );
              });
            }
          }
        </GridGallery>
      );
    }

    return <SC.UploadPlaceholderBox>{this.getUploadPlaceholder()}</SC.UploadPlaceholderBox>;
  }

  getMenu() {
    const { id } = this.props.match.params;
    const { context } = this.state;
    const { album } = this.props.app.storage.albumsDetails[id] || {};

    const publicLink = has(album, 'url') ? window.location.protocol + '//' + album.url.domain + '/' + album.url.uri : null;

    switch (context) {
      case this.viewContexts.gallery:
        // if (isMobile) {

        // }
        return (
          <Fragment>
            <SC.FloatingAddButtonBox>
              <button tabIndex="0" className="button option-button" type="button" data-tip="Add Photos" onClick={() => this.dropZoneContext.dropzoneRef.open()}>
                <i className="material-icons">add</i>
              </button>
            </SC.FloatingAddButtonBox>
            <SC.AlbumMenuBox>
              <button tabIndex="0" className="button option-button" type="button" data-tip="Add Photos" onClick={() => this.dropZoneContext.dropzoneRef.open()}>
                <i className="material-icons">add</i>
              </button>
              { // <button tabIndex="0" className="button option-button" type="button" data-tip="Share Album" onClick={() => this.setState({ share: true })}>
              }
              <Dropdown tabIndex="0" className="button option-button" type="button" data-tip="Share Album" style={{ padding: '0' }}>
                <Dropdown.Toggle
                  btnStyle="link"
                  noCaret
                  style={{
                    width: '60px',
                    height: '60px',
                    fontSize: '24px',
                    textAlign: 'center'
                  }}
                >
                  <i className="material-icons" style={{ color: '#333333' }}>share</i>
                </Dropdown.Toggle>
                <DropdownMenu>
                  <SC.LinkMenuItem>
                    <a onClick={this.closeMenuItem} rel="noopener noreferrer" target="_blank" href={publicLink}>
                      <SC.MenuItemFlexbox>
                        <i className="material-icons">open_in_new</i>
                        Go to album page
                      </SC.MenuItemFlexbox>
                    </a>
                  </SC.LinkMenuItem>
                  <SC.CopyMenuItem
                    onSelect={() => {
                      this.closeMenuItem();
                      this.shareLinkCopied();
                    }}
                  >
                    <CopyToClipboard
                      text={publicLink}
                    >
                      <SC.MenuItemFlexbox>
                        <i className="material-icons" style={{ transform: 'rotateX(180deg)' }}>filter_none</i>
                        <span>Copy share link</span>
                      </SC.MenuItemFlexbox>
                    </CopyToClipboard>
                  </SC.CopyMenuItem>
                  <MenuItem
                    onSelect={() => this.props.history.push(`/album/${id}/settings`)}
                  >
                    <SC.MenuItemFlexbox>
                      <i className="material-icons">edit</i>
                      Edit share link
                    </SC.MenuItemFlexbox>
                  </MenuItem>
                </DropdownMenu>
              </Dropdown>
              {// </button>
              }
              <Link to={`/album/${this.props.match.params.id}/settings`}>
                <button tabIndex="0" className="button option-button" type="button" data-tip="Manage Album" onClick={() => this.setState({ context: this.viewContexts.settings })}>
                  <i className="material-icons">settings</i>
                </button>
              </Link>
              <button tabIndex="0" className="button option-button" type="button" data-tip="Exit to all Albums" onClick={this.exitAlbum}>
                <i className="material-icons">arrow_back</i>
              </button>
              <ReactTooltip place="top" type="dark" effect="solid" />
            </SC.AlbumMenuBox>
          </Fragment>
        );
      case this.viewContexts.settings:
        return (
          <div style={{ whiteSpace: 'nowrap', marginTop: '6px' }}>
            <button tabIndex="0" className="button option-button" type="button" data-tip="Save and Back to Album" onClick={this.saveAlbum}>
              <i className="material-icons">save</i>
            </button>
            <button tabIndex="0" className="button option-button" type="button" data-tip="Back to Album" onClick={() => this.setState({ context: this.viewContexts.gallery })}>
              <i className="material-icons">arrow_back</i>
            </button>
            <ReactTooltip place="top" type="dark" effect="solid" />
          </div>
        );
      default:
        return (
          <div style={{ whiteSpace: 'nowrap' }}>
            <SC.MenuItemPlaceholder />
            <SC.MenuItemPlaceholder />
            <SC.MenuItemPlaceholder />
            <SC.MenuItemPlaceholder />
          </div>
        );
    }
  }

  getGallery() {
    const { ready } = this.state;
    const { id } = this.props.match.params;

    if (!ready) {
      return (
        <div key="gallery">
          <SC.AlbumTopbar>
            <div style={{ width: '100%' }}>
              <SC.TitlePlaceholder />
              <SC.BreadcrumbsPlaceholder />
            </div>
            {this.getMenu()}
          </SC.AlbumTopbar>
          {this.getPhotos()}
        </div>
      );
    }

    const { title } = this.props.app.storage.albumsDetails[id] || { title: 'New Album' };

    return (
      <div key="gallery" className="animated fadeIn">
        <Status {...this.getStatusContext()} />
        <SC.AlbumTopbar>
          <Name id={id} title={title} />
          {this.getMenu()}
        </SC.AlbumTopbar>
        {this.getPhotos()}
      </div>
    );
  }

  async loadAlbum() {
    const { id } = this.props.match.params;
    const res = await Api.getAlbum({ id });

    if (!res.success) {
      return null;
    }

    const { album, photos } = res.message;

    return {
      album,
      photos: this.getOrderedPhotos(photos, 'created')
    };
  }

  async createAlbum() {
    const res = await Api.createAlbum({ name: 'New Album', description: '' });

    this.props.history.push('/album/' + res.message.id);

    const album = await this.loadAlbum();

    return album;
  }

  async getAlbumContext() {
    const { id } = this.props.match.params;

    switch (id) {
      case 'new': {
        const createAlbum = await this.createAlbum();
        return createAlbum;
      }
      default: {
        const loadAlbum = await this.loadAlbum();
        return loadAlbum;
      }
    }
  }

  async fetchData() {
    const viewContext = await this.getAlbumContext();

    if (!viewContext) {
      return this.props.history.push('/404');
    }

    this.dispatch(setAlbumDetails(viewContext));
    this.setState({
      ready: true,
      context: this.viewContexts.gallery
    });
  }

  async componentDidMount() {
    if (!this.state.ready) {
      this.fetchData();
    }
  }

  componentWillUnmount() {
    this.fuse.unregisterSubscriberPopulator('Album');
  }

  render() {
    console.log(this);
    return (
      <AppLayout>
        <Authenticate>
          <DropZoneContext.Consumer>
            {(context) => {
              this.dropZoneContext = context;

              return this.getGallery();
            }}
          </DropZoneContext.Consumer>
        </Authenticate>
      </AppLayout>
    );
  }
}

// ########### Extending view ###########
Album.prototype.getSettings = Settings;

// ########### Export ###########
export default dConnect(Album);
